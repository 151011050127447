import { useState } from "react";
import { Button } from "components";
import { FormattedMessage, useIntl } from "services/translation";
import { useNotify } from "services/notification";
import { useRefreshOrder } from "services/orders";
import { MoveType } from "models";
import { CounselingStatusId, counselingStatusToPathMap } from "types";
import { useRouter } from "next/navigation";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "next/link";

interface ContinueButtonProps {
  moveType: MoveType;
  /**
   * The next counseling status we are expecting to transition to after
   * the current step has been successfully completed.
   */
  nextStatus?: CounselingStatusId;
  /**
   * Pass an asynchronous callback function to be fired instead of saving the move type.
   *
   * After the callback promise resolves, the order is reloaded
   * and the user is pushed to the next step. You may want to prevent
   * this follow on behavior, for example in the case where another
   * api call returned an error and you need the user to stay on the step.
   * In this case return false.
   */
  saveCallback?(moveType: MoveType): Promise<boolean | void>;
  /**
   * Whether or not to include footer text under the button.
   * Default is true.
   */
  includeHelper?: boolean;
  disabled?: boolean;
}

const HelperText = styled.div({
  marginTop: "2rem",
});

export function ContinueButton({
  moveType,
  nextStatus,
  saveCallback,
  disabled,
  includeHelper = true,
  ...rest
}: ContinueButtonProps) {
  const notify = useNotify();
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { refresh: refreshOrder } = useRefreshOrder();
  const [loading, setLoading] = useState(false);

  const continueHandler = async () => {
    setLoading(true);
    try {
      if (typeof saveCallback !== "undefined") {
        const shouldContinue = await saveCallback(moveType);
        if (shouldContinue === false) {
          return;
        }
      } else if (nextStatus) {
        moveType.counselingStatus = nextStatus;
        await moveType.save();
      }
      const newRoute = counselingStatusToPathMap[moveType.counselingStatus];
      if (moveType.counselingStatus === "COMPLETE") {
        // we have to refresh the order here so that it displays correctly after the modifications in counseling
        await refreshOrder();
      }
      router.push(newRoute);
    } catch (e) {
      notify("Something went wrong please try again", {
        variant: "error",
        autoHideDuration: 1500,
      });
      console.error(e);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        size={"block"}
        onClick={continueHandler}
        ariaLabel={formatMessage({ id: "navigation.link.continue" })}
        disabled={loading || disabled}
        {...rest}
      >
        {loading ? (
          <CircularProgress size="16px" />
        ) : (
          <FormattedMessage id="navigation.link.continue" />
        )}
      </Button>
      {includeHelper ? (
        <HelperText>
          <FormattedMessage id="counseling.haveQuestion.part1" />
          <Link href="/support">
            <FormattedMessage id="counseling.haveQuestion.part2" />
          </Link>
        </HelperText>
      ) : null}
    </>
  );
}
