"use client";

import { Box } from "@mui/system";
import { BackNav } from "components";
import { H1 } from "components/common/Typography/typography";
import { useIntl } from "services";
import { PageWrapper } from "components/common/PageWrapper";
import { SupportOptions } from "components/common/SupportOptions";

export function SupportPage() {
  const { formatMessage } = useIntl();
  return (
    <PageWrapper>
      <BackNav />
      <H1>{formatMessage({ id: "supportPage.title" })}</H1>
      <Box mt="36px">
        <SupportOptions />
      </Box>
    </PageWrapper>
  );
}
