import Link from "next/link";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "services";
import { Customer, Entitlement } from "models";
import homeImage from "./assets/home.svg";
import scss from "./PersonalInfo.module.scss";
import { UserDetailsCard } from "components/common";
import { useOrder } from "store";
import { Stack } from "@mui/material";
import truckIcon from "./assets/truck.svg";

interface PersonalInfoProps {
  customer: Customer;
  entitlement: Entitlement;
  showPPMLink?: boolean;
}

export function PersonalInfo({
  customer,
  entitlement,
  showPPMLink,
}: PersonalInfoProps) {
  const { formatMessage } = useIntl();
  const { hasEntitlementsCounseling } = useOrder();

  return (
    <div className={scss.personalInfo}>
      <UserDetailsCard customer={customer} entitlement={entitlement} />
      <Stack direction="row">
        {hasEntitlementsCounseling ? (
          <div className={classnames(scss.links)}>
            <Link
              href="/entitlements"
              aria-label={formatMessage({
                id: "ariaLabels.linkToMyEntitlements",
              })}
              className={scss.link}
            >
              <img
                src={homeImage.src}
                alt={formatMessage({ id: "imageAlt.entitlements" })}
                className={scss.linkIcon}
              />
              <span className={scss.linkText}>
                <FormattedMessage id="personalInfo.entitlementsLink" />
              </span>
            </Link>
          </div>
        ) : null}
        {showPPMLink && (
          <div className={classnames(scss.links)}>
            <Link href="/ppm-options" className={scss.link}>
              <img
                src={truckIcon.src}
                alt={formatMessage({ id: "personalInfo.ppmLink" })}
                className={scss.linkIcon}
                width="30"
                height="25"
              />
              <span className={scss.linkText}>
                <FormattedMessage id="personalInfo.ppmLink" />
              </span>
            </Link>
          </div>
        )}
      </Stack>
    </div>
  );
}
