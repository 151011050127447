"use client";
import { FormattedMessage, useIntl } from "services";
import { BackNav, BooleanRadioFields, Button } from "components";
import { CounselingLayout } from "../components";
import { counselingStatusToPathMap } from "types";
import { Form, Formik } from "formik";
import { useStorageChangeRequestForm } from "hooks/useStorageChangeRequestForm";
import { Box } from "@mui/material";
import { CounselingButton } from "../components/CounselingButton";
import { useCounselingSubmit } from "hooks";

type StorageValues = {
  storage: string;
};

export function AddressStoragePage() {
  const { formatMessage } = useIntl();
  const { initialValues, submit } = useStorageChangeRequestForm();

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "ADDRESSES_EXTRA_DELIVERY",
  });

  const onSubmit = async (values: StorageValues) => {
    await submit(values);
    await handleNext();
  };

  return (
    <CounselingLayout ariaLabel={"ariaLabels.addresses.storage"}>
      <BackNav href={counselingStatusToPathMap.ADDRESSES_DESTINATION} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.addresses.storage" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.addresses.storage.instructions" />
      </CounselingLayout.InfoText>
      <Formik
        initialValues={initialValues || ({} as StorageValues)}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Box mb="32px">
              <BooleanRadioFields name={"storage"} />
            </Box>
            <CounselingButton
              aria-label={formatMessage({ id: "navigation.link.continue" })}
              disabled={!isValid || isSubmitting}
              isSubmitting={isSubmitting || loading}
            ></CounselingButton>
          </Form>
        )}
      </Formik>
    </CounselingLayout>
  );
}
