import { Stack, Avatar, Typography, Box } from "@mui/material";
import { Employee } from "__generated__/types";

export function Crew({ crew, lead }: { crew: Employee[]; lead?: Employee }) {
  return (
    <Box mt="32px">
      <h3>Your Crew</h3>
      <Stack gap="10px" mb={3}>
        {crew.map((employee, i) => (
          <Stack direction="row" key={employee.displayName} gap={1}>
            <Avatar
              src={employee.attachments?.[0]?.url}
              alt={employee.displayName}
              sx={{ width: 44, height: 44, fontSize: "14px" }}
            >
              {employee.firstName?.[0]}
              {employee.lastName?.[0]}
            </Avatar>
            <Box lineHeight="44px">
              <Typography variant="sHeading" sx={{ alignSelf: "center" }}>
                {employee.displayName}
              </Typography>
              {employee.id === lead?.id && (
                <Typography
                  variant="xxsBody"
                  color="#666"
                  sx={{ alignSelf: "center" }}
                >
                  {" "}
                  - CREW LEAD
                </Typography>
              )}
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
