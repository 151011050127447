import classnames from "classnames";
import {
  statusPills,
  depotStatusToStatusPillMap,
  PillName,
  StatusPillConfig,
  moveTrackerStepToStatusPillMap,
} from "constant/orderStatusPillConfig";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { useOrder } from "services/orders/OrderGuard";
import starCompletedIcon from "./assets/star-completed.svg";
import starDisabledIcon from "./assets/star-disabled.svg";
import scss from "./InTransitVisibility.module.scss";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { PageWrapper } from "components/common/PageWrapper";
import { Order } from "models";
import { MoveTrackerStepStatus } from "__generated__/types";
import { useMoveTrackerSteps } from "hooks/useMoveTrackerSteps";

type Phase = "complete" | "current" | "future";

const DisplayPill = styled.div<{ phase: Phase }>`
  height: 0.75rem;
  border-radius: 1rem;
  background: ${({ phase }) =>
    phase === "current"
      ? "#f9cb24"
      : phase === "complete"
      ? "#62ce91"
      : "#e5e5e5"};
`;

const TextWrapper = styled.div`
  text-align: center;
  padding-top: 2px;
  @media (max-width: 500px) {
    display: none;
  }
`;

/**
 * Used to filter the set of displayed pills based on params on the order
 * @param pill
 * @param order
 * @returns true to display, false to not
 */
const shouldShowPill = (pill: PillName, order: Order) => {
  switch (pill) {
    case "inStorage":
      return order.hasStorage && !order.hasPartialStorage;
    case "inTransit":
      return order.hasTransportation;
    case "inStoragePartial":
      return order.hasPartialStorage;
    case "inStorageOrigin":
      return order.hasStorageAtOrigin && !order.hasStorage;
    case "deliver":
      return !order.hasStorage;
    case "finalDelivery":
      return order.hasStorage;
    default:
      return true;
  }
};

const orderStatusesWithPills = [
  "RECEIVED",
  "APPROVED",
  "COUNSELING_COMPLETED",
  "SURVEY_SCHEDULED",
  "SURVEY_IN_PROGRESS",
  "SURVEY_COMPLETED",
  "PLANNED",
  "CANCELLED",
  "UNKNOWN",
];

export function InTransitVisibility() {
  const order = useOrder();

  const { steps, moveCompletedAt } = useMoveTrackerSteps();

  let current: StatusPillConfig | undefined;
  let lastCompleted: StatusPillConfig | undefined;

  if (orderStatusesWithPills.includes(order.status)) {
    // use the legacy status pill mapping for orders in a status prior to PACK
    current = depotStatusToStatusPillMap[order.status];
  } else if (steps) {
    if (!!moveCompletedAt) {
      current = order.hasStorage
        ? { pill: "finalDelivery", markComplete: true, showStar: true }
        : { pill: "deliver", markComplete: true, showStar: true };
    } else {
      const currentStep = steps.find(
        (step) => step?.stepStatus === MoveTrackerStepStatus.Current
      );
      if (currentStep && moveTrackerStepToStatusPillMap[currentStep.key]) {
        current = moveTrackerStepToStatusPillMap[currentStep.key];
      } else {
        // if there is no current step, find the last completed step
        const lastCompletedStep = steps
          ?.slice()
          .reverse()
          .find(
            (step) =>
              step?.stepStatus === MoveTrackerStepStatus.Completed &&
              moveTrackerStepToStatusPillMap[step.key]!!
          );

        if (lastCompletedStep) {
          lastCompleted = moveTrackerStepToStatusPillMap[lastCompletedStep.key];
        }
      }
    }
  }

  const statusOptions = statusPills.filter((pill) =>
    shouldShowPill(pill, order)
  );

  const stepIndex = current
    ? statusOptions.findIndex((item) => item === current?.pill)
    : statusOptions.findIndex((item) => item === lastCompleted?.pill);

  const statusList = statusOptions.map((item, index) => {
    const phase: Phase =
      index < stepIndex ||
      (index === stepIndex && (current?.markComplete || lastCompleted!!))
        ? "complete"
        : index === stepIndex
        ? "current"
        : "future";
    return (
      <li key={item} data-testid={item}>
        <DisplayPill
          phase={phase}
          data-testid={`order-status-pill-${item}-${phase}`}
        />
        <TextWrapper>
          <Typography variant="xsHeading">
            <FormattedMessage id={`moveStatus.${item}`} />
          </Typography>
        </TextWrapper>
      </li>
    );
  });

  return (
    <div className={scss.inTransitVisibility} data-testid="move-status">
      <PageWrapper noOffset>
        <div className={classnames(scss.progressList)}>
          <ul>{statusList}</ul>
          <div className={classnames(scss.star)}>
            {current?.showStar ? (
              <img src={starCompletedIcon.src} />
            ) : (
              <img src={starDisabledIcon.src} />
            )}
          </div>
        </div>
      </PageWrapper>
    </div>
  );
}
