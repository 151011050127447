"use client";
import { FormattedMessage, useIntl } from "services";
import {
  BackNav,
  Card,
  CounselingStatusBar,
  PageContent,
  TwoColumn,
} from "components";
import { InfoText } from "./components";
import { H1 } from "components/common/Typography/typography";

export function CompletePage() {
  const { formatMessage } = useIntl();

  return (
    <div>
      <CounselingStatusBar />
      <PageContent>
        <TwoColumn>
          <div>
            <BackNav href={"/counseling/move-type"} />
            <H1>
              <FormattedMessage id="counseling.selfMove.title" />
            </H1>
            <InfoText ariaLabel={"counseling.selfMove.instructions"} />
            <Card
              title={<FormattedMessage id="counseling.selfMove.title" />}
              aria-label={formatMessage({
                id: "ariaLabels.ppm.details",
              })}
              variant="medium"
            >
              <p>
                <FormattedMessage id="counseling.selfMove.details" />
              </p>
            </Card>
          </div>
          <div>&nbsp;</div>
        </TwoColumn>
      </PageContent>
    </div>
  );
}
