import { ChangeRequest } from "models";
import { FormattedMessage, useIntl } from "services";
import { Card } from "components";
import { useModal } from "hooks";

import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { ExtraLocationType } from "types";
import { FieldComponent } from "./FieldComponent";
import { Typography } from "@mui/material";
import { useOrder } from "store";
import { ExtraStopsDialog } from "../ExtraStopsDialog";
import { SITFieldComponent } from "./SitFieldComponent";
import { ChangeRequestInfoBox } from "components/common/ChangeRequestInfoBox";

const AddressContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

styled.span({
  fontWeight: "700",
  paddingLeft: "0.25rem",
  paddingRight: "0.25rem",
});

styled.span({
  paddingRight: "0.25rem",
});

interface AddressCardProps {
  changeRequests: ChangeRequest[] | null;
}

export function AddressesCard({ changeRequests }: AddressCardProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const order = useOrder();
  const modal = useModal();
  const { formatMessage } = useIntl();

  const locations = useMemo(() => {
    const typeOrder: ExtraLocationType[] = [
      "OriginLocation",
      "DestinationLocation",
      "ExtraPickupLocation",
      "ExtraDropoffLocation",
      "SITDeliveryLocation",
    ];

    return order.locations
      .filter((location) => typeOrder.includes(location.type))
      .sort((a, b) => {
        const indexA = typeOrder.indexOf(a.type) ?? typeOrder.length;
        const indexB = typeOrder.indexOf(b.type) ?? typeOrder.length;
        return indexA - indexB;
      });
  }, [order]);

  /**
   * These represent change requests that are not tied to an existing location,
   * for example an extra drop off.
   * They are not shown in the address section.
   */
  const nonLocationChangeRequests = useMemo(() => {
    const addressChangeRequests = order.changeRequests.filter(
      (changeRequest): changeRequest is ChangeRequest<"address"> =>
        changeRequest.isAddress()
    );

    if (addressChangeRequests) {
      return addressChangeRequests.filter((changeRequest) => {
        return (
          changeRequest.oldValue === null ||
          changeRequest.changeable === undefined
        );
      });
    }
    return [];
  }, [changeRequests]);

  return (
    <>
      <Card
        title={<FormattedMessage id="addresses.title" />}
        aria-label="Addresses"
        extraTitleContent={
          <Typography
            variant="sBody"
            component="a"
            onClick={() => setModalOpen(true)}
            aria-label={formatMessage({
              id: "ariaLabels.requestAddressChange",
            })}
          >
            <FormattedMessage id="actions.requestExtraStops" />
          </Typography>
        }
      >
        <AddressContainer>
          <>
            {locations.map((location) => {
              return (
                <FieldComponent
                  key={location.id}
                  location={location}
                  changeRequests={changeRequests}
                />
              );
            })}
          </>
          {nonLocationChangeRequests.map((changeRequest) => {
            return (
              <ChangeRequestInfoBox
                key={changeRequest.id}
                changeRequest={changeRequest}
              />
            );
          })}
          <SITFieldComponent />
        </AddressContainer>
        <ExtraStopsDialog
          addresses={[]}
          modal={modal}
          modalOpen={modalOpen}
          setModalIsOpen={setModalOpen}
        />
      </Card>
    </>
  );
}
