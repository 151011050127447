import { useOrder } from "store";
import { useCrewTracker } from "services/crewTracker";
import { DepotStatus } from "types";
import React, { lazy, Suspense } from "react";
import { Skeleton } from "@mui/material";
/**
 * We lazy load the Azure map because the lib is large and also
 * it depends upon the window.
 *
 * See: https://react-azure-maps.vercel.app/live-example-map
 */
const AzureMap = lazy(() => import("./AzureMap"));

const renderMapStatuses: DepotStatus[] = [
  "PACK_CREW_DISPATCHED",
  "LOAD_CREW_DISPATCHED",
  "DELIVERY_CREW_DISPATCHED",
  "IN_STORAGE_IN_TRANSIT",
  "IN_NON_TEMPORARY_STORAGE",
  "IN_STORAGE_PARTIAL",
];

export const TrackYourMoveMap: React.FC = () => {
  const { status, inventoryTracker } = useOrder();
  const { crewTracker } = useCrewTracker();
  const isAppropriateRenderMapStatus = renderMapStatuses.includes(status);

  // the backend will only include currentLocation in the payload when the crew
  // is < 10 miles away
  const isBackendProvidingLocation =
    (!!crewTracker?.currentLocation?.latitude &&
      !!crewTracker?.currentLocation?.longitude) ||
    (!!inventoryTracker?.currentLocation?.latitude &&
      !!inventoryTracker?.currentLocation?.longitude);

  const shouldShowMap =
    isAppropriateRenderMapStatus && isBackendProvidingLocation;

  return shouldShowMap ? (
    <Suspense fallback={<Skeleton height="100px" />}>
      <AzureMap />
    </Suspense>
  ) : null;
};
