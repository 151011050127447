import { ApplicationRecord } from "models";
import { Dispatch, useEffect, useState, SetStateAction } from "react";
import { useForceUpdate } from "./useForceUpdate";

/**
 * This is a hook which allows us to use a spraypaint record in
 * a component and have the component re render when we make changes.
 *
 * ## Basic Usage:
 *
 * Assume you get your record from instantiating a class or making a query,
 * somewhere you in your code:
 *
 * ```
 * const myOrder = Orders.find(...)
 * ```
 *
 * In your component, pass it into the hook. Whenever you call
 * save() on the object, component will re-render:
 *
 * ```
 * const MyComponent = () => {
 *   useSpraypaintRecord(myOrder);
 *
 *   const onSubmit = async () => {
 *     myOrder.packDate = new Date();
 *     await myOrder.save(); // Component re-renders
 *   }
 *
 *   return ...
 * }
 * ```
 * @param record
 * @returns [record, setRecord]
 */
export const useSpraypaintRecord = <I extends ApplicationRecord>(
  record?: I
): [I | null, Dispatch<SetStateAction<I | null>>] => {
  const forceUpdate = useForceUpdate();
  const [recordState, setRecordState] = useState<I | null>(record || null);

  useEffect(() => {
    if (recordState) {
      recordState.onUpdate(forceUpdate);
      return () => {
        recordState.offUpdate(forceUpdate);
      };
    }
  }, [recordState, forceUpdate]);

  return [recordState, setRecordState];
};
