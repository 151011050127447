import { ReactNode, useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import { crewTrackerAtom, useOrder } from "store";
import { DepotStatus } from "types";
import { CrewTracker as CrewTrackerModel, Order } from "models";
import { CrewTrackerContext } from "./CrewTrackerContext";

/**
 * List of order statuses that trigger the loading of crewTracker data
 */
const shouldLoadCrewTracker: Array<DepotStatus> = [
  "PACK_CREW_DISPATCHED",
  "LOAD_CREW_DISPATCHED",
  "DELIVERY_CREW_DISPATCHED",
];

interface CrewTrackerProps {
  children: ReactNode;
  period?: number;
}

/**
 * Load the crew tracker data and periodically refresh/sync it
 * @param props
 * @returns JSX.Element
 */
export function CrewTracker(props: CrewTrackerProps) {
  const { children, period = 30000 } = props;
  const order = useOrder();
  const loadingStarted = useRef(false);
  const refreshInterval = useRef<NodeJS.Timeout>();
  const [crewTracker, setCrewTracker] = useAtom(crewTrackerAtom);
  const [isLoading, setIsLoading] = useState(true);

  async function loadCrewTracker() {
    let crewTracker: CrewTrackerModel | undefined = undefined;
    setIsLoading(true);

    if (shouldLoadCrewTracker.includes(order.status)) {
      try {
        const { data } = await Order.includes(["crew_tracker"]).find(
          order.id as string
        );
        crewTracker = data.crewTracker;
      } catch (error) {
        throw error;
      }

      setIsLoading(false);
    }

    return crewTracker;
  }

  function updateTracker() {
    loadCrewTracker().then(setCrewTracker);
  }

  useEffect(() => {
    if (!loadingStarted.current) {
      loadingStarted.current = true;
      updateTracker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearInterval(refreshInterval.current);
    refreshInterval.current = setInterval(updateTracker, period);
    return () => clearInterval(refreshInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const crewTrackerContext = { crewTracker, updateTracker, isLoading };
  return (
    <CrewTrackerContext.Provider value={crewTrackerContext}>
      {children}
    </CrewTrackerContext.Provider>
  );
}
