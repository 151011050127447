import { Button } from "components";
import { FormattedMessage, useIntl } from "services";
import styled from "@emotion/styled";
import { Typography, Box } from "@mui/material";
import Link from "next/link";

interface Props {
  /**
   * Whether or not to include footer text under the button.
   * Default is true.
   */
  includeHelper?: boolean;
  disabled?: boolean;
  onClick?(): Promise<void>;
  isSubmitting?: boolean;
}

const Wrapper = styled.div`
  width: 387px;
  margin-top: 40px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export function CounselingButton({
  onClick,
  disabled,
  isSubmitting,
  includeHelper = true,
  ...rest
}: Props) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Wrapper>
        <Button
          size={"block"}
          onClick={onClick}
          ariaLabel={formatMessage({ id: "navigation.link.continue" })}
          disabled={isSubmitting || disabled}
          isSubmitting={isSubmitting}
          type="submit"
          {...rest}
        >
          <FormattedMessage id="navigation.link.continue" />
        </Button>
      </Wrapper>
      {includeHelper ? (
        <Box mt="24px">
          <Typography variant="sBody">
            <FormattedMessage id="counseling.haveQuestion.part1" />
            <Link href="/support">
              <FormattedMessage id="counseling.haveQuestion.part2" />
            </Link>
          </Typography>
        </Box>
      ) : null}
    </>
  );
}
