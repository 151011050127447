import React, { ReactNode, useState } from "react";
import { OrderAddress } from "models";
import { Button, AddressFields, BooleanRadioFields } from "components";
import { useModal } from "hooks";
import { Formik, FormikTouched } from "formik";
import { AddressFormData, MaybeAddressFormData } from "types";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { validateAddress, validateMaybeAddress } from "tools";
import { useNotify } from "services/notification";
import { useRefreshOrder } from "services/orders";
import styled from "@emotion/styled";
import { useAddressChangeRequestForm } from "hooks/useAddressChangeRequestForm";
import MuiAccordian, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { useStorageChangeRequestForm } from "hooks/useStorageChangeRequestForm";
import { Modal } from "components/common/ui";
import { colors, theme } from "styles";
import { useIntl } from "services";

interface Props {
  addresses: Array<OrderAddress>;
  modal: ReturnType<typeof useModal>;
  modalOpen: boolean;
  setModalIsOpen(isOpen: boolean): void;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordian disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  margin: "none",
  "&:before": {
    display: "none",
  },
  ".MuiAccordionSummary-root": {
    padding: 0,
  },
  ".MuiAccordionDetails-root": {
    padding: 0,
    paddingBottom: "32px",
  },
  ".MuiAccordionSummary-content": {
    margin: "24px 0px",
  },
}));

const Wrapper = styled.div``;

const AccordianTitle = (props: { children: ReactNode }) => {
  return (
    <Typography
      variant="lHeading"
      color={theme.palette.primary.main}
      {...props}
    ></Typography>
  );
};

const makeEmptyAddress = () => ({
  street1: "",
  street2: "",
  locality: "",
  postalCode: "",
  region: "",
});

const tabs = [
  "origin",
  "destination",
  "extraPickup",
  "extraDelivery",
  "storage",
] as const;

type Tab = (typeof tabs)[number];

interface ActionRowProps {
  closeModal(): void;
  isSubmitting: boolean;
  isValid: boolean;
}

const NoChangeMessage: React.FC<{
  dirty: boolean;
  touched: FormikTouched<any>;
}> = ({ dirty, touched }) => {
  if (!dirty && Object.keys(touched).length > 0) {
    return (
      <Box color={colors.error} mt="8px">
        You have not made any changes
      </Box>
    );
  }
  return null;
};

const ActionRow: React.FC<ActionRowProps> = ({ isSubmitting }) => (
  <Box mt="32px">
    <Button
      type="submit"
      size={"block"}
      disabled={isSubmitting}
      isSubmitting={isSubmitting}
    >
      Submit
    </Button>
  </Box>
);

export function ExtraStopsDialog(props: Props) {
  const { refresh } = useRefreshOrder();
  const { formatMessage } = useIntl();
  const { initialValues: initialExtraPickupValues, submit: submitExtraPickup } =
    useAddressChangeRequestForm("EXTRA_STOP_PICKUP");
  const {
    initialValues: initialExtraDeliveryValues,
    submit: submitExtraDelivery,
  } = useAddressChangeRequestForm("EXTRA_STOP_DELIVERY");

  const { initialValues: initialStorageValues, submit: submitStorage } =
    useStorageChangeRequestForm();

  const notify = useNotify();

  const [currentTab, setCurrentTab] = useState<Tab | null>(null);

  const closeModal = () => props.setModalIsOpen(false);

  const toggleTab = (tab: Tab) => {
    return (e: React.SyntheticEvent) => {
      if (currentTab === tab) {
        setCurrentTab(null);
      } else {
        setCurrentTab(tab);
      }
    };
  };

  const makeHandleSubmit =
    <FormData,>(handler: (values: FormData) => Promise<boolean>) =>
    async (values: FormData) => {
      const result = await handler(values);
      if (result === true) {
        await refresh();
        closeModal();
        notify(
          "Your new information has been submitted and is pending approval.",
          {
            variant: "success",
            autoHideDuration: 4000,
          }
        );
      }
    };

  return (
    <Modal
      title={formatMessage({
        id: "actions.requestExtraStops",
      })}
      open={props.modalOpen}
      onClose={closeModal}
      width={500}
    >
      <Wrapper>
        <Box>
          <Accordion
            expanded={currentTab === "extraPickup"}
            onChange={toggleTab("extraPickup")}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordianTitle>Extra pick up</AccordianTitle>
            </AccordionSummary>
            <AccordionDetails>
              <Formik<MaybeAddressFormData>
                initialValues={{
                  ...makeEmptyAddress(),
                  ...initialExtraPickupValues,
                  hasAddress: initialExtraPickupValues ? "1" : "0",
                }}
                onSubmit={makeHandleSubmit(submitExtraPickup)}
                validate={validateMaybeAddress}
              >
                {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  dirty,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <p>Will there be extra pickup?</p>
                    <BooleanRadioFields name="hasAddress" />
                    {values.hasAddress === "1" ? <AddressFields /> : null}
                    <ActionRow
                      closeModal={closeModal}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                    <NoChangeMessage touched={touched} dirty={dirty} />
                  </form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={currentTab === "extraDelivery"}
            onChange={toggleTab("extraDelivery")}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordianTitle>Extra delivery</AccordianTitle>
            </AccordionSummary>
            <AccordionDetails>
              <Formik<MaybeAddressFormData>
                initialValues={{
                  ...makeEmptyAddress(),
                  ...initialExtraDeliveryValues,
                  hasAddress: initialExtraDeliveryValues ? "1" : "0",
                }}
                onSubmit={makeHandleSubmit(submitExtraDelivery)}
                validate={validateMaybeAddress}
              >
                {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  dirty,
                  touched,
                  initialValues,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <p>Will there be extra delivery?</p>
                    <BooleanRadioFields name="hasAddress" />
                    {values.hasAddress === "1" ? <AddressFields /> : null}
                    <ActionRow
                      closeModal={closeModal}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                    <NoChangeMessage touched={touched} dirty={dirty} />
                  </form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={currentTab === "storage"}
            onChange={toggleTab("storage")}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <AccordianTitle>Storage</AccordianTitle>
            </AccordionSummary>
            <AccordionDetails>
              <Formik<{ storage: string }>
                initialValues={initialStorageValues || { storage: "" }}
                onSubmit={makeHandleSubmit(submitStorage)}
                validate={(values: any) => {
                  if (!values.storage) {
                    return { storage: "Please select an option" };
                  }
                }}
              >
                {({ handleSubmit, isSubmitting, isValid, dirty, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <p>Do you need storage?</p>
                    <BooleanRadioFields name="storage" />
                    <ActionRow
                      closeModal={closeModal}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                    <NoChangeMessage touched={touched} dirty={dirty} />
                  </form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Wrapper>
    </Modal>
  );
}
