"use client";
import { useRouter } from "next/navigation";
import {
  useIntl,
  translationKeys,
  FormattedMessage,
} from "services/translation";
import { TwoColumn } from "components/common/ui/TwoColumn";
import { BackNav } from "components/common/ui/BackNav";
import { Badge } from "components/common/ui/Badge";
import { Card } from "components/common/ui/Card";
import { Skeleton, Alert, Box, Grid, Typography, Stack } from "@mui/material";
import { DepotStatus, ShipmentType } from "types";
import { useMe } from "hooks/useMe";
import { H1 } from "components/common/Typography/typography";
import { PageWrapper } from "components/common/PageWrapper";
import { useOrderLoader } from "services/orders/OrderLoaderContext";
import {
  GetMoveTaskOrdersQuery,
  useGetMoveTaskOrdersQuery,
} from "__generated__/types";
import { shipmentTypeLabelMap } from "constant/shipmentTypeLabelMap";
import { Temporal } from "@movehq/datetime";

type OrderCardProps = {
  selectOrder: (id: string) => void;
  mto: NonNullable<
    NonNullable<GetMoveTaskOrdersQuery["moveTaskOrders"]>["nodes"]
  >[0];
};

const getMoveStatusLabelKey = (status: DepotStatus): translationKeys => {
  switch (status) {
    case "CANCELLED":
      return "moveStatus.cancelled";
    case "COMPLETED":
      return "moveStatus.complete";
    case "COUNSELING_COMPLETED":
    case "SURVEY_SCHEDULED":
      return "moveStatus.survey";
    case "SURVEY_IN_PROGRESS":
      return "moveStatus.surveyInProgress";
    case "SURVEY_COMPLETED":
      return "moveStatus.surveyCompleted";
    case "PLANNED":
      return "moveStatus.surveyCompleted";
    case "IN_TRANSIT":
      return "moveStatus.inTransit";
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "IN_STORAGE_PARTIAL":
      return "moveStatus.inStorage";
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "PACK_CREW_DISPATCHED":
      return "moveStatus.pack";
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
      return "moveStatus.load";
    case "DELIVERY_COMPLETED":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
      return "moveStatus.deliver";
    case "UNKNOWN":
      return "moveStatus.unknown";
    case "RECEIVED":
    case "APPROVED":
      return "moveStatus.plan";
    default: {
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
    }
  }
};

const MoveCard = ({ selectOrder, mto }: OrderCardProps) => {
  const { origin, destination } = mto?.shipments?.[0] || {};
  const year = Temporal.PlainDate.from(mto?.shipments?.[0].receivedDate).year;

  return (
    <Card
      title={`${origin?.locality}, ${origin?.region} to ${destination?.locality}, ${destination?.region} (${year})`}
    >
      <h3>Order Type</h3>
      <Grid container columnSpacing={8} rowSpacing={4}>
        {mto?.shipments?.map((shipment) => (
          <Grid item key={shipment.id}>
            <Stack spacing={1}>
              <Typography variant="sBody">
                {shipment.shipmentType &&
                  shipmentTypeLabelMap[shipment.shipmentType as ShipmentType]}
              </Typography>
              <a
                onClick={() => selectOrder(shipment.id)}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body1">
                  Order #{shipment.number}
                </Typography>
              </a>
              {shipment.status && <MoveStatusBadge status={shipment.status} />}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const greenMoveStatuses = new Set<string>(["DELIVERY_COMPLETED", "COMPLETED"]);
const redStatuses = new Set<string>(["CANCELLED"]);
const MoveStatusBadge = ({ status }: { status: DepotStatus }) => {
  const { formatMessage } = useIntl();
  const color = greenMoveStatuses.has(status)
    ? "green"
    : redStatuses.has(status)
    ? "red"
    : "blue";

  const labelKey = getMoveStatusLabelKey(status);
  return (
    <>
      <Badge key={status} color={color}>
        {formatMessage({ id: labelKey })}
      </Badge>
    </>
  );
};

export function MyMovesPage() {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { changeOrder } = useOrderLoader();
  const me = useMe();
  const handleChange = (id: string) => {
    changeOrder(id);
    router.push("/");
  };

  const { data, loading, error } = useGetMoveTaskOrdersQuery();

  const moves = data?.moveTaskOrders?.nodes || [];

  return (
    <PageWrapper>
      <TwoColumn>
        <div>
          <BackNav ariaLabel={"ariaLabels.returnHome"} />
          <H1 mb="32px">My Moves</H1>
          {error && (
            <Box my={4}>
              <Alert severity="error">
                <FormattedMessage id="myMoves.error" />
              </Alert>
            </Box>
          )}
          {!loading && moves.length === 0 ? (
            <div>
              {formatMessage(
                {
                  id: "myMoves.noOrders",
                },
                { email: me?.email || "" }
              )}
            </div>
          ) : null}
          {loading
            ? [1, 2, 3].map((i) => (
                <Card key={i} title={<Skeleton height={20} width="1" />}>
                  <Skeleton variant="rectangular" height={150} width="1" />
                </Card>
              ))
            : moves.map(
                (mto) =>
                  mto && (
                    <MoveCard
                      key={mto.id}
                      mto={mto}
                      selectOrder={handleChange}
                    />
                  )
              )}
        </div>
      </TwoColumn>
    </PageWrapper>
  );
}
