"use client";
import { FormattedMessage } from "services";
import { BackNav } from "components";
import { useOrder } from "store";
import { useCounselingSubmit } from "hooks";
import { CounselingLayout } from "../components";
import { counselingStatusToPathMap } from "types";
import { AddressCounselingCard } from "./AddressCounselingCard";
import { CounselingButton } from "../components/CounselingButton";
import { useState } from "react";
import { AddressChangeDialog } from "components/common/AddressChangeDialog";

export function AddressDestinationPage() {
  const order = useOrder();
  const [modalOpen, setModalOpen] = useState(false);
  const changeRequest = order.activeChangeRequest("DESTINATION_ADDRESS");
  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "STORAGE_IN_TRANSIT",
  });
  return (
    <CounselingLayout ariaLabel={"ariaLabels.addresses.destination"}>
      <BackNav href={counselingStatusToPathMap.ADDRESSES_EXTRA_PICKUP} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.addresses.destination.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.addresses.destination.instructions" />
      </CounselingLayout.InfoText>

      <AddressCounselingCard
        mode="destination"
        changeRequest={changeRequest}
        toggleModal={() => setModalOpen(true)}
        address={order.destination}
      />
      <CounselingButton onClick={handleNext} isSubmitting={loading} />
      <AddressChangeDialog
        changeRequestType={"DESTINATION_ADDRESS"}
        locationId={order.destinationLocation?.id}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Destination"
      />
    </CounselingLayout>
  );
}
