"use client";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import { Alert, Typography } from "@mui/material";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { TwoColumn } from "components/common/ui/TwoColumn";
import { AlertBar } from "components/common/ui/AlertBar";
import { PageWrapper } from "components/common/PageWrapper";
import { PersonalInfo } from "./DetailsSection/components/PersonalInfo";
import { DetailsSection } from "./DetailsSection";
import { InTransitVisibility } from "./InTransitVisibility";
import { ManageSection } from "./ManageSection";
import { CancelledContent } from "./CancelledContent";
import { CounselingStatus } from "./CounselingStatus";
import { useOrder } from "services/orders/OrderGuard";

import { useOrderLoader } from "services/orders/OrderLoaderContext";
import { useGetOrderByIdQuery } from "__generated__/types";
import { useRouter } from "next/navigation";
import {
  CounselingStatusId,
  counselingStatusToPathMap,
} from "types/CounselingStatus";
const tabs = ["manage", "details"] as const;

type Tab = (typeof tabs)[number];

const tabTitles: { [s in Tab]: string } = {
  manage: "Manage Move",
  details: "Move Details",
};

const SectionToggle = styled.div<{ current: boolean }>`
  display: ${(props) => (props.current ? "block" : "none")};
`;

export function HomePage() {
  const theme = useTheme();
  const order = useOrder();
  const isCancelled = order.status === "CANCELLED";
  const isInOnboarding = order.isInOnboarding;
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [selectedTabMobile, setSelectedTabMobile] = useState<Tab>("manage");
  const { changeOrder } = useOrderLoader();
  const router = useRouter();
  const { data } = useGetOrderByIdQuery({
    variables: {
      id: order.id!,
    },
    fetchPolicy: "cache-and-network",
  });

  const orderWithIncompleteOnboarding = useMemo(() => {
    if (
      data?.order?.moveTaskOrder?.shipments &&
      data?.order?.moveTaskOrder?.shipments?.length > 1
    ) {
      const incomplete = data?.order?.moveTaskOrder?.shipments.find(
        (shipment) => shipment.counselingStatus !== "COMPLETE"
      );
      return incomplete;
    }
  }, [data]);

  const handleIncompleteCounseling = () => {
    if (orderWithIncompleteOnboarding) {
      changeOrder(orderWithIncompleteOnboarding?.id);
      const counselingStatus =
        orderWithIncompleteOnboarding?.counselingStatus as CounselingStatusId;
      router.push(counselingStatusToPathMap[counselingStatus]);
    }
  };

  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    index: number
  ) => {
    setSelectedTabMobile(tabs[index]);
  };

  if (isCancelled) {
    return (
      <>
        <AlertBar>
          <FormattedMessage id="canceledStatusPage.title" />
        </AlertBar>
        <PageWrapper>
          <TwoColumn style={{ paddingTop: theme.spacing(5) }}>
            <CancelledContent />
            <div>
              <PersonalInfo
                customer={order.customer}
                entitlement={order.entitlement}
              />
            </div>
          </TwoColumn>
        </PageWrapper>
      </>
    );
  }

  if (isInOnboarding) {
    return <CounselingStatus />;
  }

  return (
    <>
      {!!orderWithIncompleteOnboarding && (
        <AlertBar>
          <div
            onClick={handleIncompleteCounseling}
            style={{ cursor: "pointer" }}
          >
            <FormattedMessage id="dashboard.banner.multipleShipments" />
          </div>
        </AlertBar>
      )}
      <InTransitVisibility />
      {isDesktop === false ? (
        <Tabs
          onChange={handleTabChange}
          value={tabs.indexOf(selectedTabMobile)}
          aria-label="section tabs"
        >
          {tabs.map((tab) => {
            return (
              <Tab
                sx={{
                  flex: "0 0 50%",
                }}
                key={tab}
                label={
                  <Typography variant="sHeading">{tabTitles[tab]}</Typography>
                }
              ></Tab>
            );
          })}
        </Tabs>
      ) : null}
      <PageWrapper>
        {isDesktop ? (
          <TwoColumn reverse>
            <ManageSection />
            <DetailsSection />
          </TwoColumn>
        ) : (
          <Box>
            <SectionToggle current={selectedTabMobile === "manage"}>
              <ManageSection />
            </SectionToggle>
            <SectionToggle current={selectedTabMobile === "details"}>
              <DetailsSection />
            </SectionToggle>
          </Box>
        )}
      </PageWrapper>
    </>
  );
}
