"use client";
import { FormattedMessage, useIntl } from "services";
import { useOrder } from "store";
import { BackNav, Panel } from "components";
import styled from "@emotion/styled";
import { CounselingLayout } from "./components";
import { useState } from "react";
import { MoveType } from "models";
import { counselingStatusToPathMap, MoveTypeId } from "types";
import { useCounselingSubmit } from "hooks";
import { CounselingButton } from "./components/CounselingButton";
const Incentive = styled.div({
  fontWeight: "bold",
});

export function MoveTypePage() {
  const { formatMessage } = useIntl();
  const order = useOrder();

  const [continueButtonActive, setContinueButtonActive] = useState(
    order.moveType.moveType !== null
  );

  const [selectedMoveType, setSelectedMoveType] = useState<MoveTypeId>(
    order.moveType.moveType
  );

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "ADDRESSES_ORIGIN",
    saveCallback: async (moveType: MoveType) => {
      if (selectedMoveType && selectedMoveType === "PERSONALLY_PROCURED") {
        moveType.counselingStatus = "PPM";
      } else if (selectedMoveType && selectedMoveType === "FULL_SERVICE") {
        moveType.moveType = selectedMoveType;
      }
      return true;
    },
  });

  const clickMoveType = (selectedMoveType: MoveTypeId) => {
    setSelectedMoveType(selectedMoveType);
    setContinueButtonActive(true);
  };

  return (
    <CounselingLayout ariaLabel={"ariaLabels.counseling.moveType"}>
      {order.hasEntitlementsCounseling ? (
        <BackNav href={counselingStatusToPathMap.ENTITLEMENTS_FORMS} />
      ) : null}
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.moveType.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.moveType.instructions" />
      </CounselingLayout.InfoText>
      <Panel
        icon="boxes_and_lamp"
        title={<FormattedMessage id="counseling.moveType.fsm.title" />}
        ariaLabel={formatMessage({
          id: "ariaLabels.counseling.moveType.fsm",
        })}
        onClick={() => {
          clickMoveType("FULL_SERVICE");
        }}
        completed={selectedMoveType === "FULL_SERVICE"}
      >
        <FormattedMessage id="counseling.moveType.fsm.message" />
      </Panel>
      {/*
        TODO: When PPM is supported again uncomment this code.
      <Panel
        icon="truck"
        title={<FormattedMessage id="counseling.moveType.ppm.title" />}
        ariaLabel={formatMessage({
          id: "ariaLabels.counseling.moveType.ppm",
        })}
        onClick={() => {
          clickMoveType("PERSONALLY_PROCURED");
        }}
        completed={selectedMoveType === "PERSONALLY_PROCURED"}
      >
        <FormattedMessage id="counseling.moveType.ppm.message" />
        <Incentive>
          <FormattedMessage
            id="counseling.moveType.ppm.incentive"
            values={{ incentive: "100" }}
          />
        </Incentive>
      </Panel>
      */}

      <CounselingButton
        disabled={!continueButtonActive}
        onClick={handleNext}
        isSubmitting={loading}
      />
    </CounselingLayout>
  );
}
