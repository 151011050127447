import { createContext, useContext } from "react";
import { CrewTracker } from "models";

export interface CrewTrackerInstance {
  updateTracker: () => void;
  isLoading: boolean;
  crewTracker: CrewTracker | undefined;
}

export const CrewTrackerContext = createContext<CrewTrackerInstance>({
  updateTracker: () => null,
  isLoading: true,
  crewTracker: undefined,
});

export function useCrewTracker() {
  return useContext(CrewTrackerContext);
}
