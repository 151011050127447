import { FormattedMessage, useIntl, translationKeys } from "services";
import { H1, P } from "components/common/Typography/typography";
import { Button } from "components/common";
import { useOrder } from "store";
import { counselingStatusToPathMap } from "types";
import { AlertBar } from "components/common/ui/AlertBar";
import { InTransitVisibility } from "./InTransitVisibility";
import { TwoColumn } from "components";
import { DetailsSection } from "./DetailsSection";
import Link from "next/link";
import { PageWrapper } from "components/common/PageWrapper";

export const CounselingStatus: React.FC = () => {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const counselingStatus = order.moveType?.counselingStatus;
  const linkHref = counselingStatusToPathMap[counselingStatus];
  return (
    <>
      <AlertBar>
        <Link href={linkHref}>
          <FormattedMessage id="landingPage.counselingAlert" />
        </Link>
      </AlertBar>
      <InTransitVisibility />
      <PageWrapper>
        <TwoColumn style={{ paddingTop: "5px" }} reverse>
          <div className="page-title">
            <H1
              aria-label={formatMessage({ id: "landingPage.counselingTitle" })}
              mb="8px"
            >
              <FormattedMessage id="landingPage.counselingTitle" />
            </H1>
            <P mb="16px">
              <FormattedMessage id="landingPage.counselingSubheading" />
            </P>

            <Button to={linkHref}>
              <FormattedMessage id="landingPage.counselingCta" />
            </Button>
          </div>
          <DetailsSection />
        </TwoColumn>
      </PageWrapper>
    </>
  );
};
