"use client";
import { LoadingDisplay } from "components";
import { Button } from "components/common/ui/Button";
import { VonageVideo } from "components/common/VonageVideo";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "next/navigation";
import { VirtualSurveyCredentials } from "models/VirtualSurveyCredentials";
import { useVideoCredentials } from "hooks/useVideoCredentials";
import { Alert } from "@mui/material";
import checklistSvg from "./checklist.svg";
import { Box } from "@mui/system";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

type PageState = "intro" | "live";

export const SurveyVideo = () => {
  const [pageState, setPageState] = useState<PageState>("intro");
  const searchParams = useSearchParams();
  const serviceId = searchParams?.get("serviceId");
  const [credentials, setCredentials] =
    useState<VirtualSurveyCredentials | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const getVideoCredentials = useVideoCredentials();
  useEffect(() => {
    setLoading(true);
    setError(null);
    getVideoCredentials(serviceId as string)
      .then((c) => {
        setLoading(false);
        setCredentials(c);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }, [getVideoCredentials, serviceId]);

  if (loading) {
    return <LoadingDisplay />;
  }

  if (credentials === null || error) {
    return (
      <Alert severity="error">
        Something went wrong and your virtual meeting is not available right
        now.
      </Alert>
    );
  }

  return (
    <Wrapper>
      {pageState === "intro" ? (
        <div>
          <Box textAlign={"center"}>
            <h2>Survey Call Basics</h2>
          </Box>
          <Box display={"flex"} alignItems="center" flexDirection="column">
            <img src={checklistSvg.src} />
            <Box maxWidth="360px">
              <Box mb="32px">
                <ul>
                  <li>Allow camera and microphone access</li>
                  <li>
                    Make sure there is plenty of natural light in each room.
                  </li>
                  <li>
                    Move the camera slowly as guided by your survey agent.
                  </li>
                </ul>
              </Box>
              <Button
                variant="primary"
                size="block"
                onClick={() => setPageState("live")}
              >
                Let`s go!
              </Button>
            </Box>
          </Box>
        </div>
      ) : null}
      {pageState === "live" ? (
        <VonageVideo
          apiKey={credentials.apiKey}
          sessionId={credentials.sessionId}
          token={credentials.token}
        />
      ) : null}
    </Wrapper>
  );
};
