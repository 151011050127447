/**
 * Checks if string matches a valid us phone number
 * in terms of number of digits and extension,
 * then pretty formats it like so:
 *
 * `(111) 222-3333`
 *
 * If the string is not a valid number,
 * it will return the original string.
 * @param str Input string
 * @returns Formatted number or original string if cannot be formatted
 */
export const formatUSPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return str;
};
