import { ChangeRequest } from "models";
import { useMemo } from "react";
import { useOrder } from "services/orders";
import { AddressFormData, ChangeRequestId, MaybeAddressFormData } from "types";
import { isEqual } from "lodash";
import { Location } from "models/Location";
import {
  useCreateUpdateLocationChangeRequestMutation,
  useCreateAddLocationChangeRequestMutation,
  useCancelChangeRequestMutation,
} from "__generated__/types";
import { getCountyFromAddress } from "tools/geo";
import { countryOptions } from "constant/countryOptions";

const extractAddressFields = (
  formData?: AddressFormData
): AddressFormData | undefined => {
  if (!formData) {
    return undefined;
  }
  const { street1, street2, locality, postalCode, region } = formData;
  return { street1, street2, locality, postalCode, region };
};

const getLocationAddress = (location: Location) => {
  return {
    locationId: location.id,
    street1: location.streetAddress,
    street2: location.streetAddress2,
    locality: location.city,
    postalCode: location.postalCode,
    region: location.state,
  };
};

const locationNameToType = (locationName: ChangeRequestId): string => {
  switch (locationName) {
    case "ORIGIN_ADDRESS":
      return "OriginLocation";
    case "DESTINATION_ADDRESS":
      return "DestinationLocation";
    case "EXTRA_STOP_PICKUP":
      return "ExtraPickupLocation";
    case "EXTRA_STOP_DELIVERY":
      return "ExtraDropoffLocation";
    case "SIT_DELIVERY_ADDRESS":
      return "SITDeliveryLocation";
    default:
      return "UnsupportedLocationType";
  }
};

/**
 * Provides the initial values and a submit function to send a new change
 * request for an address.
 * This hook deals with logic to:
 * - Find both the current saved value and any pending requests, and determine
 *   which to use as the initial value of the the form
 * - Submit a new request only if we need to
 * @param requestType
 */
export const useAddressChangeRequestForm = (
  requestType: ChangeRequestId,
  /**
   * Pass a locationId if this is an address change request for an existing location.
   * If not, leave out.
   */
  locationId?: string
) => {
  const order = useOrder();

  const addressChangeRequests = order.changeRequests.filter(
    (changeRequest): changeRequest is ChangeRequest<"address"> =>
      changeRequest.isAddress()
  );

  const mostRecentPendingRequest = useMemo(() => {
    const requests = addressChangeRequests.filter(
      (request) =>
        request.name === requestType &&
        (locationId === undefined || request.changeable?.id === locationId) &&
        request.isPending()
    );

    // Since we could have multiple pending requests for the same location, get the most recent
    if (requests.length > 0) {
      return requests.reduce((a, b) => {
        return a.updatedAt > b.updatedAt ? a : b;
      });
    }
  }, [requestType, order]);

  const currentSavedValue: AddressFormData | undefined = useMemo(() => {
    if (locationId) {
      const location = order.locations.find((s) => s.id === locationId);
      if (location) {
        return getLocationAddress(location);
      }
    }
  }, [order, requestType]);

  const initialValues = useMemo(() => {
    // If there an existing request, use those as the initial value
    if (mostRecentPendingRequest) {
      return mostRecentPendingRequest.newValue || undefined;
    } else {
      return currentSavedValue;
    }
  }, [currentSavedValue, mostRecentPendingRequest]);

  const [createUpdateLocationChangeRequest, { loading: updateLoading }] =
    useCreateUpdateLocationChangeRequestMutation();

  const [createAddLocationChangeRequest, { loading: addLoading }] =
    useCreateAddLocationChangeRequestMutation();

  const [cancelChangeRequest, { loading: cancelLoading }] =
    useCancelChangeRequestMutation();

  const submit = async (
    values: AddressFormData | MaybeAddressFormData
  ): Promise<boolean> => {
    // Special case for user requesting to remove an address, for stops
    if ("hasAddress" in values && values.hasAddress === "0") {
      if (
        mostRecentPendingRequest !== undefined &&
        mostRecentPendingRequest.id
      ) {
        const { data } = await cancelChangeRequest({
          variables: {
            input: {
              id: mostRecentPendingRequest.id,
            },
          },
          onError: (error) => {
            console.error(error);
          },
        });

        return !!data?.cancelChangeRequest?.changeRequest;
      } else {
        return false;
      }
    }

    // Compare with initial values of the form, which may be the previous change request,
    // since that's how we know if anything changed. But we send `currentSavedValue` as the old value
    // since any pending requests will be ignored.
    if (
      !isEqual(
        extractAddressFields(values),
        extractAddressFields(initialValues)
      )
    ) {
      if (!order.id) {
        return false;
      }

      const county = await getCountyFromAddress(values);

      if (locationId) {
        const { data } = await createUpdateLocationChangeRequest({
          variables: {
            input: {
              orderId: order.id,
              locationId,
              newAddress: {
                street1: values.street1,
                street2: values.street2 || "",
                locality: values.locality,
                postalCode: values.postalCode,
                region: values.region,
                country: countryOptions.UnitedStates,
                county: county || "",
              },
            },
          },
          onError: (error) => {
            console.error(error);
          },
        });
        return !!data?.createUpdateLocationChangeRequest?.changeRequest;
      } else {
        const { data } = await createAddLocationChangeRequest({
          variables: {
            input: {
              orderId: order.id,
              locationType: locationNameToType(requestType),
              newAddress: {
                street1: values.street1,
                street2: values.street2 || "",
                locality: values.locality,
                postalCode: values.postalCode,
                region: values.region,
                country: countryOptions.UnitedStates,
                county: county || "",
              },
            },
          },
          onError: (error) => {
            console.error(error);
          },
        });
        return !!data?.createAddLocationChangeRequest?.changeRequest;
      }
    }

    return false;
  };

  const disableForm =
    mostRecentPendingRequest?.disallowResubmission ||
    updateLoading ||
    addLoading ||
    cancelLoading ||
    false;

  return { initialValues, submit, disableForm };
};
