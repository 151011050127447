import {
  AddressesCard,
  DatesCard,
  DocumentsCard,
  PersonalInfo,
} from "./components";
import { useIntl } from "services";
import { useOrder } from "store";
import { DepotStatus } from "types";
import { WeightsCard } from "./components/WeightsCard";
import { useMoveTrackerSteps } from "hooks/useMoveTrackerSteps";
import { useFlags } from "services/launch-darkly";

const shouldShowDatesCard = (status: DepotStatus) => {
  switch (status) {
    case "COMPLETED":
    case "CANCELLED":
      return false;
    default:
      return true;
  }
};

const shouldShowWeights = (status: DepotStatus) => {
  switch (status) {
    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "IN_STORAGE_PARTIAL":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
    case "DELIVERY_COMPLETED":
    case "COMPLETED":
      return true;
    case "APPROVED":
    case "CANCELLED":
    case "COUNSELING_COMPLETED":
    case "PLANNED":
    case "RECEIVED":
    case "SURVEY_COMPLETED":
    case "SURVEY_IN_PROGRESS":
    case "SURVEY_SCHEDULED":
    case "UNKNOWN":
      return false;
    default:
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
  }
};
export function DetailsSection() {
  const flags = useFlags();
  const { formatMessage } = useIntl();
  const order = useOrder();
  const {
    customer,
    moveStageDates: dates,
    changeRequests,
    entitlement,
  } = order;

  return (
    <div aria-label={formatMessage({ id: "ariaLabels.detailSections" })}>
      <PersonalInfo
        customer={customer}
        entitlement={entitlement}
        showPPMLink={flags.ppmPortal}
      />
      {shouldShowDatesCard(order.status) ? (
        <DatesCard dates={dates} orderId={order.id!} />
      ) : null}
      {shouldShowWeights(order.status) ? <WeightsCard /> : null}
      <AddressesCard changeRequests={changeRequests} />
      <DocumentsCard orderId={order.id || ""} />
    </div>
  );
}
