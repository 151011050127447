import { useState, useEffect } from "react";
import { Service } from "models/Service";
import { ServiceDocument } from "models/ServiceDocument";
import { FormattedMessage } from "services";
import { Card } from "components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

type DocumentAndService = {
  document: ServiceDocument;
  service: Service;
};
export function DocumentsCard({ orderId }: { orderId: string }) {
  const [documentsLoaded, setDocumentsLoaded] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentAndService[] | null>(null);

  useEffect(() => {
    if (!documentsLoaded) {
      Service.where({ orderId })
        .includes(["documents"])
        .all()
        .then((result) => {
          const documents = result.data.reduce(
            (acc: DocumentAndService[], service: Service) => {
              const documents = service.documents.map((document) => ({
                service,
                document,
              }));
              return [...acc, ...documents];
            },
            []
          );
          setDocumentsLoaded(true);
          setDocuments(documents);
        });
    }
  });

  return (
    <Card
      title={<FormattedMessage id="documents.title" />}
      aria-label="Order Documents List"
    >
      <Box component="ul" m="0" p="0" sx={{ listStyle: "inside none" }}>
        {documents &&
          documents.map(({ service, document }, index) => (
            <Box component="li" mb="5px" key={index} aria-label={document.name}>
              <div data-testid="pdf-document">
                <Typography variant="sBody">
                  <a
                    href={document.links.canonical ?? document.links.direct}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {service.displayName} - {document.displayName}
                  </a>
                </Typography>
              </div>
            </Box>
          ))}
      </Box>
    </Card>
  );
}
