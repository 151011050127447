"use client";
import { FormattedMessage } from "services";
import { useEntitlementsLoadable } from "store";
import { BackNav, LoadingDisplay } from "components";
import { H1 } from "components/common/Typography/typography";
import { Box, Typography } from "@mui/material";
import { EntitlementsContent } from "./EntitlementsContent";
import { PageWrapper } from "components/common/PageWrapper";
import { EntitlementsWrapper } from "./EntitlementsWrapper";
import { Agreements } from "components/common/Agreements";
import { EntitlementsAdditionalInfo } from "./EntitlementsAdditionalInfo";

export function EntitlementsPage() {
  const entitlementsLoadable = useEntitlementsLoadable();
  if (entitlementsLoadable.state === "loading") {
    return <LoadingDisplay />;
  } else if (entitlementsLoadable.state === "hasData") {
    return (
      <PageWrapper>
        <BackNav ariaLabel={"ariaLabels.returnHome"} />
        <Box mb="32px">
          <H1>
            <FormattedMessage id="entitlements.title" />
          </H1>
        </Box>
        <EntitlementsWrapper>
          <EntitlementsAdditionalInfo />
          <Box mb="16px">
            <Typography variant="xlHeading" component="h3">
              <FormattedMessage id="entitlements.forms.heading" />
            </Typography>
          </Box>
          <Agreements readonly showAll />
        </EntitlementsWrapper>
      </PageWrapper>
    );
  }
  return null;
}
