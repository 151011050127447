import { Panel, HighlightUnbackedData } from "components";
import { FormattedMessage, useIntl } from "services";
import { Button } from "components";
import { Box } from "@mui/material";
import { MouseEvent } from "react";
import { Service } from "models";
import { formatTime, stringToLocalPlainDateTime } from "@movehq/datetime";
import Link from "next/link";

const defaultWindowSettings =
  "width=500,height=550,left=150,top=200,popup,resizable=yes";

export function useOpenVideoPopup(
  path: string,
  name: string,
  settings?: string
) {
  const openVideo = () => {
    const handle = window.open(path, name, settings ?? defaultWindowSettings);
    if (!handle) {
      // TODO: Show something in the UI
      console.warn("Video window was not allowed to open.");
    }
  };

  return openVideo;
}

interface Props {
  service: Service;
}

export const SurveyCallScheduledPanel: React.FC<Props> = ({ service }) => {
  const { formatMessage } = useIntl();

  const videoPageUrl = `/survey-video?serviceId=${service?.id}`;
  const openVideo = useOpenVideoPopup(videoPageUrl, "survey-video");
  const handleOpenVideo = (e: MouseEvent) => {
    e.preventDefault();
    openVideo();
  };

  if (!service.plannedDatetimeRange || !service.plannedDatetimeRange.begin) {
    return (
      <Panel
        icon="phone"
        title={"Schedule your survey"}
        variant="primary"
        ariaLabel={formatMessage({ id: "ariaLabels.surveyCallScheduledPanel" })}
      >
        Contact your HomeSafe customer care to schedule a day and time
        you&apos;re available for conducting the survey.{" "}
        <strong>
          Please call at 904-567-6033 or use the Live Chat button in the lower
          right.
        </strong>
      </Panel>
    );
  }

  return (
    <Panel
      icon="phone"
      title={<FormattedMessage id="surveyScheduledPanel.title" />}
      variant="primary"
      ariaLabel={formatMessage({ id: "ariaLabels.surveyCallScheduledPanel" })}
      completed
    >
      <HighlightUnbackedData>
        <FormattedMessage
          id={"surveyScheduledPanel.virtualMessage"}
          values={{
            date: (
              <strong>
                {formatTime(
                  stringToLocalPlainDateTime(
                    service.plannedDatetimeRange.begin,
                    service.plannedDatetimeRange.begin.match(/\[(.*?)\]/)?.[1]
                  ),
                  "numericDateTimeZone"
                )}
              </strong>
            ),
          }}
        />

        <Box mt="16px">
          <Button
            onClick={handleOpenVideo}
            size="medium"
            to={videoPageUrl}
            ariaLabel={formatMessage({
              id: "ariaLabels.launchVirtualSurveyButton",
            })}
          >
            <FormattedMessage id="surveyPanel.virtual.button" />
          </Button>
        </Box>
      </HighlightUnbackedData>
    </Panel>
  );
};
