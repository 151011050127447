"use client";
import { FormattedMessage, useIntl } from "services";
import { useRefreshOrder } from "services/orders";
import { AddressFields, BackNav, BooleanRadioFields } from "components";
import styled from "@emotion/styled";
import { CounselingLayout } from "../components";
import { counselingStatusToPathMap } from "types";
import { Form, Formik } from "formik";
import { validateAddress } from "tools/formValidation";
import { Box } from "@mui/system";
import { useAddressChangeRequestForm } from "hooks/useAddressChangeRequestForm";
import { MaybeAddressFormData } from "types/Forms";
import { CounselingButton } from "../components/CounselingButton";
import { useCounselingSubmit } from "hooks";
import { useOrder } from "store";

const AddressContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginTop: "0.5rem",
  marginBottom: "1rem",
});

const validate = (values: Partial<MaybeAddressFormData>) => {
  if (!values.hasAddress) {
    return {
      extraStop: "Please select an option",
    };
  }
  if (values.hasAddress === "1") {
    return validateAddress(values);
  }
};

export function AddressExtraDeliveryPage() {
  const { refresh } = useRefreshOrder();
  const { formatMessage } = useIntl();
  const { locations } = useOrder();
  const dropoffLocation = locations.find(
    (l) => l.type === "ExtraDropoffLocation"
  );
  const { initialValues, submit } = useAddressChangeRequestForm(
    "EXTRA_STOP_DELIVERY",
    dropoffLocation?.id
  );

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "MOVE_DATES",
  });

  const onSubmit = async (values: MaybeAddressFormData) => {
    const result = await submit(values);
    if (result !== false) {
      await refresh();
    }
    await handleNext();
  };

  return (
    <CounselingLayout ariaLabel={"ariaLabels.addresses.extraDelivery"}>
      <BackNav href={counselingStatusToPathMap.STORAGE_IN_TRANSIT} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.addresses.delivery" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.addresses.delivery.instructions" />
      </CounselingLayout.InfoText>
      <div>
        <Formik
          initialValues={
            initialValues
              ? { ...initialValues, hasAddress: "1" }
              : ({} as MaybeAddressFormData)
          }
          onSubmit={onSubmit}
          validate={validate}
          validateOnMount
        >
          {({ values, isValid, isSubmitting }) => (
            <Form>
              <Box mb="32px">
                <BooleanRadioFields name={"hasAddress"} />
              </Box>
              {values.hasAddress === "1" && (
                <AddressContainer>
                  <AddressFields />
                </AddressContainer>
              )}
              <CounselingButton
                aria-label={formatMessage({ id: "navigation.link.continue" })}
                disabled={!isValid}
                isSubmitting={loading || isSubmitting}
              ></CounselingButton>
            </Form>
          )}
        </Formik>
      </div>
    </CounselingLayout>
  );
}
