import { DepotStatus } from "types";
import { Panel } from "components/common/ui/Panel";
import { useOrder } from "services/orders/OrderGuard";
import { OnsiteSurveyPanel } from "./OnsiteSurveyPanel";
import { SurveyCallScheduledPanel } from "./SurveyCallScheduledPanel";
import { ReviewYourBelongingsPanel } from "../ReviewYourBelongingsPanel";
import { FormattedMessage, useIntl } from "services";
import { useRefreshOrder } from "services/orders";
import { useSpraypaintRecord } from "hooks/useSpraypaintRecord";

const shouldShow = (status: DepotStatus) => {
  switch (status) {
    case "APPROVED":
    case "RECEIVED":
    case "COUNSELING_COMPLETED":
    case "SURVEY_COMPLETED":
    case "SURVEY_IN_PROGRESS":
    case "SURVEY_SCHEDULED":
    case "PLANNED": {
      return true;
    }
    default:
      return false;
  }
};

export const SurveyPanel: React.FC = () => {
  const order = useOrder();
  const surveyService = order.scheduledSurveyService;
  useSpraypaintRecord(surveyService);

  const { formatMessage } = useIntl();
  if (!shouldShow(order.status)) {
    return null;
  }

  if (surveyService) {
    switch (surveyService.status) {
      case "IN_REVIEW": {
        return (
          <Panel
            icon="couch"
            title={
              <FormattedMessage id="reviewBelongings.surveyPendingPanelTitle" />
            }
            ariaLabel={formatMessage({
              id: "reviewBelongings.surveyPendingPanelTitle",
            })}
            completed
          >
            <FormattedMessage id="reviewBelongings.surveyPending" />
          </Panel>
        );
      }

      case "PERFORMED":
      case "READY_FOR_ACCOUNTING": {
        return <ReviewYourBelongingsPanel />;
      }

      default: {
        if (surveyService.subtype === "ONSITE") {
          return <OnsiteSurveyPanel service={surveyService} />;
        }

        if (surveyService.subtype === "VIRTUAL") {
          return <SurveyCallScheduledPanel service={surveyService} />;
        }
      }
    }
  }

  return (
    <Panel
      icon="phone"
      title={"Error with your survey"}
      variant="primary"
      ariaLabel="Error with your survey"
    >
      There is an issue with administering your Survey. Please contact Homesafe
      Customer Care.
    </Panel>
  );
};
