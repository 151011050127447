import { useEffect, useMemo, useRef } from "react";
import { Panel } from "components/common/ui/Panel";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { CrewTracker } from "services/crewTracker";
import { fetchBlob } from "tools";
import { useOrder } from "store";
import { Crew } from "./components/Crew";
import { StatusDetails } from "./components/StatusDetails";
import { TrackYourMoveMap } from "./components/TrackYourMoveMap";
import { TrackYourMoveTimeline } from "./components/TrackYourMoveTimeline";
import scss from "./TrackYourMovePanel.module.scss";
import { Alert, Box, Skeleton, Stack } from "@mui/material";
import { ContactCrew } from "./components/ContactCrew";
import { DepotStatus } from "types";
import { Step } from "hooks/useMoveTrackerSteps";

const shouldShowTracker = (status: DepotStatus) => {
  switch (status) {
    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
    case "DELIVERY_COMPLETED":
    case "IN_STORAGE_PARTIAL":
      return true;
    case "APPROVED":
    case "CANCELLED":
    case "COMPLETED":
    case "COUNSELING_COMPLETED":
    case "PLANNED":
    case "RECEIVED":
    case "SURVEY_COMPLETED":
    case "SURVEY_IN_PROGRESS":
    case "SURVEY_SCHEDULED":
    case "UNKNOWN":
      return false;
    default: {
      // forces us to account for every case
      const _exhaustiveCheck: never = status;
      return _exhaustiveCheck;
    }
  }
};

const shouldShowCrew = (status: DepotStatus) => {
  switch (status) {
    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "DELIVERY_CREW_DISPATCHED":
    case "DELIVERY_STARTED":
      return true;
    default:
      return false;
  }
};

const StepsLoading = () => (
  <Stack direction="column" spacing="5px">
    <Box display="flex" alignItems={"center"}>
      <Skeleton
        animation="wave"
        variant="circular"
        width={40}
        height={40}
        sx={{ mr: "10px" }}
      />
      <Skeleton animation="wave" height={20} width="80%" />
    </Box>
    <Box display="flex" alignItems={"center"}>
      <Skeleton
        animation="wave"
        variant="circular"
        width={40}
        height={40}
        sx={{ mr: "10px" }}
      />
      <Skeleton animation="wave" height={20} width="80%" />
    </Box>
    <Box display="flex" alignItems={"center"}>
      <Skeleton
        animation="wave"
        variant="circular"
        width={40}
        height={40}
        sx={{ mr: "10px" }}
      />
      <Skeleton animation="wave" height={20} width="80%" />
    </Box>
  </Stack>
);

interface TrackYourMovePanelProps {
  moveTracker: {
    steps?: Step[] | null;
    loading: boolean;
    error: boolean;
  };
}

export function TrackYourMovePanel({ moveTracker }: TrackYourMovePanelProps) {
  const order = useOrder();
  const urlsToUnload = useRef<Array<string>>([]);

  const { steps } = moveTracker;
  const currentStep =
    steps && steps.find((step) => step?.stepStatus === "CURRENT");
  const service = currentStep?.service;
  const delivered = steps?.find(
    (step) => step?.key === "DELIVERY" && step?.stepStatus === "COMPLETED"
  );

  const employees = useMemo(() => {
    if (
      service &&
      service.crewAssignments &&
      service.crewAssignments.length > 0
    ) {
      return service.crewAssignments.map((assignment) => assignment.asset);
    }
    return [];
  }, [service]);

  const crewPhoneNumber = service?.serviceProviderPhoneNumber || null;
  const crewName = service?.serviceProviderName || null;

  const displayCrewPersonnel = !!(
    employees?.length &&
    employees?.length > 0 &&
    shouldShowCrew(order.status) &&
    crewPhoneNumber &&
    crewName
  );

  const lead = useMemo(() => {
    return service?.crewAssignments?.find((ca) => ca.lead)?.asset;
  }, [service]);

  useEffect(() => {
    const currentUrlsToUnload = urlsToUnload.current;
    document
      .querySelectorAll(`.${scss.deliveryCrew} img`)
      .forEach((item: Element) => {
        const id = item.id;
        const attr = item.attributes.getNamedItem("data-imgurl");
        const url = attr?.value;
        if (url) {
          currentUrlsToUnload.push(url);
          displayProtectedImage(id, url);
        }
      });
    return () => {
      currentUrlsToUnload.forEach(unloadAuthedBinary);
    };
  }, []);

  return (
    <CrewTracker>
      <Panel
        ariaLabel={`Track your move. Current step is ${currentStep?.key}`}
        data-test-id="track-your-move-panel"
        icon="truck"
        title={
          <FormattedMessage
            id={delivered ? "moveDetails.title" : "trackYourMove.title"}
          />
        }
      >
        {shouldShowTracker(order.status) ? (
          <>
            {moveTracker.loading ? <StepsLoading /> : null}
            {moveTracker.error ? (
              <Alert severity="error">Error loading move steps</Alert>
            ) : steps && !moveTracker.loading ? (
              <>
                {delivered && (
                  <Box mb="12px">
                    <FormattedMessage id="trackYourMove.expandedMessage" />
                  </Box>
                )}
                <StatusDetails
                  steps={steps}
                  currentStep={currentStep || null}
                />
                <TrackYourMoveMap />
                <TrackYourMoveTimeline steps={steps} />
                {displayCrewPersonnel && (
                  <>
                    <Crew crew={employees || []} lead={lead} />
                    <ContactCrew
                      contactNumber={crewPhoneNumber}
                      spName={crewName}
                    />
                  </>
                )}
              </>
            ) : null}
          </>
        ) : (
          <FormattedMessage id="trackYourMove.message" />
        )}
      </Panel>
    </CrewTracker>
  );
}

function unloadAuthedBinary(binary: string) {
  URL.revokeObjectURL(binary);
}

async function displayProtectedImage(imageId: string, imageUrl: string) {
  const blob = await fetchBlob(imageUrl);
  const objectUrl = URL.createObjectURL(blob);
  const imageElement: HTMLImageElement = document.getElementById(
    imageId
  ) as HTMLImageElement;
  imageElement.src = objectUrl;
}
