"use client";
import { BackNav, Button, PageContent } from "../../common";
import classnames from "classnames";
import scss from "./EntitlementsPage.module.scss";
import { FormattedMessage, useIntl } from "../../../services";
import { useOrder } from "../../../store";
import styled from "@emotion/styled";
import { counselingStatusToPathMap } from "../../../types";
import { useRouter } from "next/navigation";
import { PpmContent } from "../../common/PpmContent/PpmContent";

const ContinueWrapper = styled.div({
  height: 142,
  alignSelf: "stretch",
  flexGrow: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  backgroundColor: "#ffffff",
  position: "fixed",
  right: 0,
  left: 0,
  padding: "3rem 15%",
  bottom: 0,
  borderTop: "1px solid #cccccc",
});

const ButtonWrapper = styled.div({
  minWidth: "20rem",
});

const SubTextConfirmButton = styled.div({
  color: "#01314a",
  fontSize: "0.75rem",
  lineHeight: "1.25rem",
});

export function CounselingPpmPage() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const router = useRouter();
  const { customer, entitlement, moveType } = order;

  const continueHandler = async () => {
    moveType.counselingStatus = "COMPLETE";
    moveType.moveType = "PERSONALLY_PROCURED";

    await moveType.save();

    const newRoute = "/selfmove";

    router.push(newRoute);
  };

  const backPath = order.hasEntitlementsCounseling
    ? counselingStatusToPathMap.ENTITLEMENTS_FORMS
    : counselingStatusToPathMap.WELCOME;

  return (
    <div>
      <PageContent
        aria-label={formatMessage({ id: "ariaLabels.selfMovePortal" })}
        className={classnames(scss.entitlements)}
      >
        <BackNav href={backPath} />
        <PpmContent customer={customer} entitlement={entitlement} />
        <ContinueWrapper>
          <ButtonWrapper>
            <Button
              size={"block"}
              onClick={continueHandler}
              ariaLabel={formatMessage({ id: "navigation.link.continue" })}
            >
              <FormattedMessage id="counseling.ppm.ConfirmButton" />
            </Button>
          </ButtonWrapper>
          <SubTextConfirmButton>
            <FormattedMessage id="counseling.ppm.ConfirmButtonSubText" />
          </SubTextConfirmButton>
        </ContinueWrapper>
      </PageContent>
    </div>
  );
}
