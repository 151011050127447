import { ReactNode } from "react";
import { CounselingStatusBar, LeftColumn, TwoColumn } from "components";
import { translationKeys, useIntl } from "services";
import { Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { PageWrapper } from "components/common/PageWrapper";

interface CounselingLayoutProps {
  ariaLabel: translationKeys;
  children?: ReactNode;
  rightColumnContent?: ReactNode;
}

export function CounselingLayout({
  ariaLabel,
  children,
  rightColumnContent,
}: CounselingLayoutProps) {
  const { formatMessage } = useIntl();

  return (
    <div>
      <CounselingStatusBar />
      <Fade in={true} timeout={1000}>
        <div>
          <PageWrapper aria-label={formatMessage({ id: ariaLabel })}>
            {rightColumnContent ? (
              <TwoColumn>
                <div>{children}</div>
                <div>{rightColumnContent}</div>
              </TwoColumn>
            ) : (
              <LeftColumn>{children}</LeftColumn>
            )}
          </PageWrapper>
        </div>
      </Fade>
    </div>
  );
}

const CounselingLayoutInfoText = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Typography variant="mBody" component="p" mb="24px">
      {children}
    </Typography>
  );
};

const CounselingLayoutHeading = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Typography variant="xxlHeading" component="h1" mb="8px">
      {children}
    </Typography>
  );
};

CounselingLayout.Heading = CounselingLayoutHeading;
CounselingLayout.InfoText = CounselingLayoutInfoText;
