"use client";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { useIntl } from "services/translation/Intl";
import { useRefreshOrder } from "services/orders";
import { AddressFields, BackNav, BooleanRadioFields } from "components/common";
import styled from "@emotion/styled";
import { CounselingLayout } from "../components";
import { counselingStatusToPathMap } from "types";
import { Form, Formik } from "formik";
import { validateAddress } from "tools/formValidation";
import { MaybeAddressFormData } from "types/Forms";
import { Box } from "@mui/system";
import { useAddressChangeRequestForm } from "hooks/useAddressChangeRequestForm";
import { CounselingButton } from "../components/CounselingButton";
import { useCounselingSubmit } from "hooks/useCounselingSubmit";
import { useOrder } from "store";

const AddressContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginBottom: "32px",
});

const validate = (values: Partial<MaybeAddressFormData>) => {
  if (!values.hasAddress) {
    return {
      extraStop: "Please select an option",
    };
  }
  if (values.hasAddress === "1") {
    return validateAddress(values);
  }
};

export function AddressExtraPickupPage() {
  const { formatMessage } = useIntl();
  const { refresh } = useRefreshOrder();
  const { locations } = useOrder();
  const pickupLocation = locations.find(
    (l) => l.type === "ExtraPickupLocation"
  );
  const { initialValues, submit } = useAddressChangeRequestForm(
    "EXTRA_STOP_PICKUP",
    pickupLocation?.id
  );

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "ADDRESSES_DESTINATION",
  });

  const onSubmit = async (values: MaybeAddressFormData) => {
    const result = await submit(values);
    if (result !== false) {
      await refresh();
    }
    await handleNext();
  };

  return (
    <CounselingLayout ariaLabel={"ariaLabels.addresses.extraPickup"}>
      <BackNav href={counselingStatusToPathMap.ADDRESSES_ORIGIN} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.addresses.pickup" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.addresses.pickup.instructions" />
      </CounselingLayout.InfoText>
      <div>
        <Formik
          initialValues={
            initialValues
              ? { ...initialValues, hasAddress: "1" }
              : ({} as MaybeAddressFormData)
          }
          onSubmit={onSubmit}
          validate={validate}
          validateOnMount
        >
          {({ values, isValid, isSubmitting }) => (
            <Form>
              <Box mb="32px">
                <BooleanRadioFields name={"hasAddress"} />
              </Box>
              {values.hasAddress === "1" && (
                <AddressContainer>
                  <AddressFields />
                </AddressContainer>
              )}
              <CounselingButton
                aria-label={formatMessage({ id: "navigation.link.continue" })}
                disabled={!isValid}
                isSubmitting={loading || isSubmitting}
              ></CounselingButton>
            </Form>
          )}
        </Formik>
      </div>
    </CounselingLayout>
  );
}
