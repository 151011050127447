import classnames from "classnames";
import { useOrder } from "store";
import { EntitlementsCard } from "components";
import { BackToTopButton } from "./components";
import scss from "./Entitlements.module.scss";
import { Box, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { SectionNavProvider } from "./SectionNavContext";

const StickyContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const Sticky = styled.div`
  position: sticky;
  top: 32px;
`;

interface Props {
  children: React.ReactNode;
}

export const EntitlementsWrapper: React.FC<Props> = ({ children }) => {
  const order = useOrder();
  return (
    <SectionNavProvider>
      <Grid
        container
        columnSpacing={{ xs: "88px", md: "44px", lg: "88px" }}
        columns={{ xs: 1, md: 12 }}
      >
        <Grid item xs={1} md={5}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            pb="32px"
          >
            <StickyContainer>
              <Sticky>
                <div className={classnames(scss.entitlementCard)}>
                  <EntitlementsCard
                    customer={order.customer}
                    entitlement={order.entitlement}
                  />
                </div>
              </Sticky>
            </StickyContainer>
          </Box>
        </Grid>

        <Grid item xs={1} md={7}>
          <div className={classnames(scss.detailsList)}>{children}</div>
        </Grid>
      </Grid>
    </SectionNavProvider>
  );
};
