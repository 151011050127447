import styled from "@emotion/styled";
import { FormattedMessage, translationKeys } from "services";

export const Container = styled.p({
  marginBottom: "3.5rem",
});

interface InfoTextProps {
  ariaLabel: translationKeys;
}

export function InfoText({ ariaLabel }: InfoTextProps) {
  return (
    <Container>
      <FormattedMessage id={ariaLabel} />
    </Container>
  );
}
