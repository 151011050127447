import { ChangeRequest } from "models";
import { useMemo, useState } from "react";
import { MoveStageDate } from "types";
import { Temporal, stringToPlainDate, nowPlainDate } from "@movehq/datetime";

// Hook which gives us the initial values, current form values and
// logic to make the correct change requests when we save dates
export const useDateChangeRequestForm = (
  dates: MoveStageDate[],
  orderId: string
) => {
  const currentDate = useMemo(() => {
    return nowPlainDate();
  }, []);

  const isDateStillValid = (moveStageDate: MoveStageDate, date: string) => {
    // Ignore the preferred moveStageDate if it's in the past unless the service was already completed
    return (
      Temporal.PlainDate.compare(currentDate, date) < 0 ||
      moveStageDate.completedDate !== null
    );
  };

  const initialDates = useMemo(
    () =>
      dates.map((moveStageDate) => {
        // If there are any existing change requests use that as the inital
        // value to indicate to the user that they are changing the change request
        if (moveStageDate.pendingChangeRequest?.newValue) {
          const pendingValue = moveStageDate.pendingChangeRequest.newValue.date;

          if (pendingValue && isDateStillValid(moveStageDate, pendingValue)) {
            return stringToPlainDate(pendingValue);
          }
        }
        if (
          moveStageDate.preferredDate &&
          isDateStillValid(moveStageDate, moveStageDate.preferredDate)
        ) {
          return stringToPlainDate(moveStageDate.preferredDate);
        }
        return null;
      }),
    [dates]
  );

  const [selectedDates, setSelectedDates] = useState(initialDates);

  const setSelectedDate = (index: number, value: Temporal.PlainDate | null) => {
    setSelectedDates((dates) => {
      return dates.map((date, i) => (i === index ? value : date));
    });
  };

  const clearSelectedDates = () => {
    setSelectedDates(initialDates);
  };

  const handleSubmit = async () => {
    const requests = dates
      .map((moveStageDate, i) => {
        const initialDate = initialDates[i]?.toString();
        const inputDate = selectedDates[i]?.toString();
        const preferredDate = moveStageDate.preferredDate;

        // Only send a change request if it has changed from the initial value
        if (
          inputDate &&
          (initialDate === undefined || initialDate !== inputDate)
        ) {
          const changeRequestDate = new ChangeRequest({
            orderId: orderId,
            name: moveStageDate.changeRequestId,
            oldValue: {
              date: preferredDate,
            },
            newValue: {
              date: inputDate,
            },
          });
          return changeRequestDate.save();
        }
        return null;
      })
      .filter((item) => item !== null);

    if (requests.length > 0) {
      await Promise.all(requests);
      return true;
    }
    return false;
  };

  const areDatesSequential = () => {
    return selectedDates.every((date, index) => {
      const previousDate = selectedDates[index - 1];
      if (date !== null && index > 0 && previousDate !== null) {
        return Temporal.PlainDate.compare(previousDate, date) <= 0;
      }
      return true;
    });
  };

  const minDate = (previousDate: Temporal.PlainDate | null) => {
    if (!previousDate) {
      return currentDate;
    }
    return Temporal.PlainDate.compare(currentDate, previousDate) < 0
      ? previousDate
      : currentDate;
  };

  return {
    handleSubmit,
    initialDates,
    selectedDates,
    setSelectedDate,
    dates,
    clearSelectedDates,
    areDatesSequential,
    minDate,
  };
};
