import { useIntl, FormattedMessage } from "services";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import styled from "@emotion/styled";
import { hsaPhoneNumber } from "../../../constant/contact";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { H1 } from "components/common/Typography/typography";

const IconWrapper = styled.div`
  color: #ad1919;
  display: inline-block;
  margin-right: 16px;
  font-size: 40px;
  position: relative;
  top: 4px;
`;

const Phone = styled.a`
  color: #105990;
  margin-bottom: 12px;
  text-decoration: none !important;
`;

export const CancelledContent: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div aria-label={formatMessage({ id: "ariaLabels.manageSections" })}>
      <div className="page-title">
        <Box mb="16px">
          <H1 aria-label={formatMessage({ id: "ariaLabels.landingPageTitle" })}>
            <IconWrapper>
              <WarningAmberRoundedIcon fontSize="large" />
            </IconWrapper>
            <FormattedMessage id="canceledStatusPage.title" />
          </H1>
        </Box>
        <Box mb="18px" maxWidth="500px">
          <Typography variant="lBody">
            <FormattedMessage id="canceledStatusPage.info" />
          </Typography>
        </Box>
        <Typography variant="xxlBody">
          <Phone href={`tel:${hsaPhoneNumber}`}>{hsaPhoneNumber}</Phone>
        </Typography>
        <div>
          <Typography variant="sBody">
            <FormattedMessage id="canceledStatusPage.contactInfo" />
          </Typography>
        </div>
      </div>
    </div>
  );
};
