import { Order, OrderAddress } from "models";
import { AddressFormData, ChangeRequestId } from "types";
import { EditAddressForm, Modal } from "components";
import { useAddressChangeRequestForm } from "hooks/useAddressChangeRequestForm";
import { useRefreshOrder } from "services/orders";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNotify } from "services/notification";

interface AddressChangeDialogProps {
  /**
   * @deprecated prop
   */
  address?: OrderAddress;
  /**
   * @deprecated prop
   */
  order?: Order;
  title: string;
  changeRequestType: ChangeRequestId;
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  /**
   * Pass a locationId if this is an address change request for an existing location.
   * If not, leave out.
   */
  locationId?: string;
}

export function AddressChangeDialog({
  changeRequestType,
  isOpen,
  setIsOpen,
  title,
  locationId,
}: AddressChangeDialogProps) {
  const notify = useNotify();
  const { refresh } = useRefreshOrder();
  const { initialValues, submit } = useAddressChangeRequestForm(
    changeRequestType,
    locationId
  );

  const onSubmit = async (values: AddressFormData) => {
    const result = await submit(values);
    if (result) {
      await refresh();
      notify("Address change request submitted", { variant: "success" });
      setIsOpen(false);
    } else {
      notify("Failed to submit address change request. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title="Request Address Change"
    >
      <Box mb="28px">
        <Typography variant="lHeading">{title}</Typography>
      </Box>
      <EditAddressForm
        address={new OrderAddress(initialValues)}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}
