import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { PanelSelect } from "components/common";
import { formatTime, Temporal } from "@movehq/datetime";

interface Props {
  timeSlots: Array<{
    startTime: Temporal.PlainTime;
    endTime: Temporal.PlainTime;
    id: string;
  }>;
  onSelect(id: string): void;
  selected: string | null;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    width: 100%;
    min-width: 100%;
  }
  @media (min-width: 500px) {
    > * {
      width: 48%;
      min-width: 48%;
    }
  }
`;

export const TimeSlotList: React.FC<Props> = ({
  timeSlots,
  onSelect,
  selected,
}) => {
  return (
    <Wrapper>
      {timeSlots.map((slot) => {
        const formattedTime =
          formatTime(slot.startTime, "numericDateTime") +
          "-" +
          formatTime(slot.endTime, "numericDateTime");

        return (
          <Box key={slot.id} mb="8px" aria-label="time-slot">
            <PanelSelect
              selected={slot.id === selected}
              onClick={() => onSelect(slot.id)}
              aria-label={formattedTime}
              role="button"
            >
              <div>{formattedTime}</div>
            </PanelSelect>
          </Box>
        );
      })}
    </Wrapper>
  );
};
