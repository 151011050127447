"use client";
import { FormattedMessage, useIntl } from "services";
import { useEntitlementsLoadable, useOrder } from "store";
import {
  CounselingStatusBar,
  LoadingDisplay,
  Button,
  BackNav,
} from "components";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { CounselingLayout } from "./components";
import { Box, Typography } from "@mui/material";
import { useAtom, atom } from "jotai";
import { useCounselingSubmit } from "hooks";

import { PageWrapper } from "components/common/PageWrapper";
import { EntitlementsWrapper } from "../EntitlementsPage/EntitlementsWrapper";
import { EntitlementsAdditionalInfo } from "../EntitlementsPage/EntitlementsAdditionalInfo";
import { Agreements } from "components/common/Agreements";
import { counselingStatusToPathMap } from "types";

// Use an atom so we remember the state if we nav
// back to the entitlements page
const hasReviewedEntitlementsAtom = atom(false);

const PageHeader = styled.div({
  flex: "0 0 100%",
});

const ContinueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //width: 56%;
  margin-left: auto;
  padding: 26px 0;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  @media (max-width: 960px) {
    width: 80%;
  }
`;

export function EntitlementsPage() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const {
    hasEntitlementsCounseling,
    counselingEntitlementsService,
    hasCompletedEntitlementsCounseling,
  } = order;
  const entitlementsLoadable = useEntitlementsLoadable();
  const [hasReviewedEntitlements, setHasReviewedEntitlements] = useAtom(
    hasReviewedEntitlementsAtom
  );
  const counselingService = counselingEntitlementsService;

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "ADDRESSES_ORIGIN",
    saveCallback: () => {
      if (counselingService && counselingService?.status !== "PERFORMED") {
        counselingService.completionStatus = "PERFORMED";
        return counselingService.save();
      }
      return Promise.resolve(true);
    },
  });

  useEffect(() => {
    // If there's no counseling then we just require
    // customer to read all the info and scroll to bottom
    // of screen
    if (!hasEntitlementsCounseling) {
      const onScroll = () => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 100
        ) {
          setHasReviewedEntitlements(true);
        }
      };

      window.addEventListener("scroll", onScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [setHasReviewedEntitlements, hasEntitlementsCounseling]);

  if (entitlementsLoadable.state === "loading") {
    return <LoadingDisplay />;
  } else if (entitlementsLoadable.state === "hasData") {
    return (
      <div>
        <CounselingStatusBar />

        <PageWrapper
          aria-label={formatMessage({ id: "ariaLabels.entitlements" })}
        >
          <BackNav href={counselingStatusToPathMap.ENTITLEMENTS_PREFERENCES} />
          <PageHeader>
            <CounselingLayout.Heading>
              <FormattedMessage id="entitlements.title" />
            </CounselingLayout.Heading>
            <CounselingLayout.InfoText>
              <FormattedMessage id="entitlements.subtext" />
              <br />
              <FormattedMessage id="entitlements.subtext2" />
            </CounselingLayout.InfoText>
          </PageHeader>

          <EntitlementsWrapper>
            <EntitlementsAdditionalInfo />
            <Box mb="16px">
              <Typography variant="xlHeading" component="h3">
                <FormattedMessage id="entitlements.forms.heading" />
              </Typography>
            </Box>
            <Typography variant="mBody">
              <FormattedMessage id="entitlements.forms.subHeadingOnboarding" />
            </Typography>
            <Agreements
              onComplete={(isComplete: boolean) => {
                setHasReviewedEntitlements(isComplete);
              }}
              readonly={false}
            />
            <ContinueWrapper>
              <ButtonWrapper>
                <Button
                  disabled={!hasReviewedEntitlements}
                  onClick={handleNext}
                  isSubmitting={loading}
                  size={"block"}
                  ariaLabel={formatMessage({
                    id: "navigation.link.continue",
                  })}
                >
                  Continue
                </Button>
              </ButtonWrapper>

              <Typography
                variant="xsBody"
                textAlign="center"
                padding="0px 24px"
              >
                {hasReviewedEntitlements ? (
                  <FormattedMessage id="counseling.entitlements.alwaysChangeMoveType" />
                ) : (
                  <FormattedMessage
                    id={
                      hasEntitlementsCounseling
                        ? "counseling.entitlements.agreementRequired"
                        : "counseling.entitlements.scrollMessage"
                    }
                  />
                )}
              </Typography>
            </ContinueWrapper>
          </EntitlementsWrapper>
        </PageWrapper>
      </div>
    );
  }
  return null;
}
