import { FormattedMessage, useIntl, translationKeys } from "services";
import { useOrder } from "store";
import { formatTime } from "@movehq/datetime";
import { getOrderStatusValue } from "tools";
import { ReviewYourMovePanel } from "./ReviewYourMovePanel";
import {
  CounselingStatusId,
  counselingStatusToPathMap,
  DepotStatus,
  ShipmentType,
} from "types";
import { H1, P } from "components/common/Typography/typography";
import { TrackYourMovePanel } from "./TrackYourMovePanel/TrackYourMovePanel";
import { SurveyPanel } from "./SurveyCallScheduledPanel/SurveyPanel";
import { useMoveTrackerSteps } from "hooks/useMoveTrackerSteps";
import Link from "next/link";
import { useGetOrderByIdQuery } from "__generated__/types";
import { useMemo } from "react";
import { shipmentTypeLabelMap } from "constant/shipmentTypeLabelMap";
import { useOrderLoader } from "services/orders";

const getTitleId = (orderStatus: DepotStatus): translationKeys => {
  switch (orderStatus) {
    case "RECEIVED":
    case "APPROVED":
    case "COUNSELING_COMPLETED":
    case "SURVEY_SCHEDULED":
    case "SURVEY_IN_PROGRESS":
    case "SURVEY_COMPLETED":
    case "PLANNED": {
      return "landingPage.manageYourMove";
    }

    case "PACK_CREW_DISPATCHED":
    case "PACKING_STARTED":
    case "PACKING_COMPLETED":
    case "LOAD_CREW_DISPATCHED":
    case "LOADING_STARTED":
    case "IN_TRANSIT":
    case "IN_STORAGE_IN_TRANSIT":
    case "IN_NON_TEMPORARY_STORAGE":
    case "DELIVERY_CREW_DISPATCHED":
    case "IN_STORAGE_PARTIAL":
    case "DELIVERY_STARTED":
    case "DELIVERY_COMPLETED":
    case "COMPLETED": {
      return "landingPage.moveInProgress";
    }
    case "CANCELLED":
    case "UNKNOWN": {
      return "landingPage.manageYourMove";
    }

    default: {
      // forces us to account for every case
      const _exhaustiveCheck: never = orderStatus;
      return "landingPage.manageYourMove";
    }
  }
};

const getSubtitleId = (status: DepotStatus): translationKeys | null => {
  const currentStatusValue = getOrderStatusValue(status);
  const packStatusValue = getOrderStatusValue("PACK_CREW_DISPATCHED"); // 40
  const deliveredStatusValue = getOrderStatusValue("DELIVERY_COMPLETED"); // 90 (previously 95)

  const isNotPacked = currentStatusValue < packStatusValue;
  const isNotDelivered = currentStatusValue < deliveredStatusValue;

  switch (true) {
    case isNotPacked:
      return "landingPage.completeTasks";

    case isNotDelivered:
      return null;

    default:
      return null;
  }
};

type SubtitleProps = {
  subtitleId: translationKeys | null;
  dateTimeCompleted?: string | null;
};
const Subtitle = ({ subtitleId, dateTimeCompleted }: SubtitleProps) => {
  const { formatMessage } = useIntl();

  return subtitleId ? (
    <P
      aria-label={formatMessage({
        id: "ariaLabels.landingPageSubTitle",
      })}
    >
      <FormattedMessage id={subtitleId} values={{ dateTimeCompleted }} />
    </P>
  ) : null;
};

export function ManageSection() {
  const { formatMessage } = useIntl();
  const { status, shipmentTypeLabel, id: orderId } = useOrder();
  const { changeOrder } = useOrderLoader();
  const { loading, error, steps, moveCompletedAt } = useMoveTrackerSteps();
  const subtitleId = moveCompletedAt
    ? "landingPage.timeComplete"
    : getSubtitleId(status);

  const titleId = moveCompletedAt
    ? "landingPage.moveComplete"
    : getTitleId(status);
  const dateTimeCompleted =
    moveCompletedAt && formatTime(moveCompletedAt, "dateAtTime");

  const { data: orderData } = useGetOrderByIdQuery({
    variables: { id: orderId! },
    skip: !orderId,
  });

  const otherShipment = useMemo(() => {
    return orderData?.order?.moveTaskOrder?.shipments.find(
      (shipment) => shipment.id !== orderId
    );
  }, [orderData]);

  const handleChangeShipment = () => {
    if (otherShipment?.id) {
      changeOrder(otherShipment?.id);
    }
  };

  const otherShipmentLink = () => {
    return (
      <Link
        href={
          counselingStatusToPathMap[
            otherShipment?.counselingStatus as CounselingStatusId
          ]
        }
        onClick={handleChangeShipment}
      >
        {shipmentTypeLabelMap[otherShipment?.shipmentType as ShipmentType]}{" "}
        Shipment
      </Link>
    );
  };

  return (
    <div aria-label={formatMessage({ id: "ariaLabels.manageSections" })}>
      <div className="page-title">
        <H1
          aria-label={formatMessage({ id: "ariaLabels.landingPageTitle" })}
          mb="8px"
        >
          {titleId == "landingPage.manageYourMove" ? (
            <FormattedMessage id={titleId} values={{ shipmentTypeLabel }} />
          ) : (
            <FormattedMessage id={titleId} />
          )}
        </H1>
        {otherShipment && otherShipment.shipmentType ? (
          <FormattedMessage
            id="landingPage.otherShipmentLink"
            values={{
              otherShipmentLink: otherShipmentLink(),
            }}
          />
        ) : null}
        <Subtitle
          subtitleId={subtitleId}
          dateTimeCompleted={dateTimeCompleted}
        />
      </div>
      <SurveyPanel />
      <TrackYourMovePanel moveTracker={{ loading, error: !!error, steps }} />
      <ReviewYourMovePanel />
    </div>
  );
}
