import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { FormattedMessage } from "services";

const Wrapper = styled.div`
  background: rgba(236, 146, 142, 0.22);
  color: #410e0b;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #410e0b;
    text-decoration: none;
  }
`;

interface Props {
  children: ReactNode;
}

export const AlertBar: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Typography variant="sHeading">{children}</Typography>
    </Wrapper>
  );
};
