import { translationKeys, useIntl } from "services";
import React from "react";
import { ChangeRequestId, ExtraLocationType } from "types";
import styled from "@emotion/styled";
import { ChangeRequest, Location, OrderAddress } from "models";
import { Box, IconButton, Typography } from "@mui/material";
import { ChangeRequestInfoBox } from "components/common/ChangeRequestInfoBox";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Address } from "components";
import { AddressChangeDialog } from "components/common/AddressChangeDialog";

const InfoBoxContainer = styled.div({
  paddingTop: "1rem",
});

export const getLatestChangeRequestByName = (
  name: ChangeRequestId,
  changeRequests: ChangeRequest[] | null
) => {
  if (changeRequests) {
    return changeRequests
      .filter((changeRequest) => {
        return changeRequest.name === name;
      })
      .sort((resultA, resultB) => {
        return (
          new Date(resultB.updatedAt).getTime() -
          new Date(resultA.updatedAt).getTime()
        );
      })[0];
  }
};

const locationTypeToChangeRequestType = (
  locationType: ExtraLocationType
): ChangeRequestId => {
  switch (locationType) {
    case "OriginLocation":
      return "ORIGIN_ADDRESS";
    case "DestinationLocation":
      return "DESTINATION_ADDRESS";
    case "ExtraPickupLocation":
      return "EXTRA_STOP_PICKUP";
    case "ExtraDropoffLocation":
      return "EXTRA_STOP_DELIVERY";
    case "SITDeliveryLocation":
      return "SIT_DELIVERY_ADDRESS";
  }
};

const getLatestChangeRequest = (
  locationId: string,
  changeRequests: ChangeRequest<"address">[] | null
) => {
  if (changeRequests) {
    return changeRequests
      .filter((changeRequest) => {
        return changeRequest.changeable?.id === locationId;
      })
      .sort((resultA, resultB) => {
        return (
          new Date(resultB.updatedAt).getTime() -
          new Date(resultA.updatedAt).getTime()
        );
      })[0];
  }
};

const convertLocationInfoIntoAddressForDisplay = (
  location: Location
): OrderAddress => {
  const address = new OrderAddress({
    street1: location.streetAddress,
    street2: location.streetAddress2,
    locality: location.city,
    region: location.state,
    postalCode: location.postalCode,
  });

  return address;
};

const getAddressLabel = (locationType: ExtraLocationType): translationKeys => {
  switch (locationType) {
    case "ExtraPickupLocation":
      return "moveTracker.address.changeRequest.ExtraPickup";
    case "ExtraDropoffLocation":
      return "moveTracker.address.changeRequest.ExtraDelivery";
    case "OriginLocation":
      return "moveTracker.address.changeRequest.Origin";
    case "DestinationLocation":
      return "moveTracker.address.changeRequest.Destination";
    case "SITDeliveryLocation":
      return "moveTracker.address.changeRequest.SitDelivery";
    default:
      return "moveTracker.address.changeRequest.General";
  }
};

interface FieldComponentProps {
  changeRequests: ChangeRequest[] | null;
  location: Location;
}

export function FieldComponent({
  changeRequests,
  location,
}: FieldComponentProps) {
  const { formatMessage } = useIntl();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const changeRequest = getLatestChangeRequest(location.id!, changeRequests);
  const title = formatMessage({
    id: getAddressLabel(location.type),
  });
  return (
    <section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Typography variant="xxsHeading">{title}</Typography>

          <Typography
            variant="sBody"
            data-testid={`move-tracker-address-${location.type}`}
            data-dd-privacy="mask"
          >
            <Address
              address={convertLocationInfoIntoAddressForDisplay(location)}
            />
          </Typography>
        </div>

        <IconButton
          onClick={() => setEditModalOpen(true)}
          aria-label={`Request ${location.type} Address Change`}
        >
          <BorderColorIcon />
        </IconButton>
      </Box>

      {changeRequest ? (
        <InfoBoxContainer>
          <ChangeRequestInfoBox changeRequest={changeRequest} />
        </InfoBoxContainer>
      ) : null}
      <AddressChangeDialog
        isOpen={editModalOpen}
        setIsOpen={setEditModalOpen}
        changeRequestType={locationTypeToChangeRequestType(location.type)}
        locationId={location.id}
        title={title}
      />
    </section>
  );
}
