"use client";
import { ErrorDisplay } from "components";
import { PageWrapper } from "components/common/PageWrapper";

export function NotFoundPage() {
  return (
    <PageWrapper>
      <ErrorDisplay>404 Not Found</ErrorDisplay>
    </PageWrapper>
  );
}
