import { DepotStatus } from "types";
import { MoveTrackerKey } from "__generated__/types";

/**
 * Ordered list of the pills displayed on the top
 * of the dashboard
 */
export const statusPills = [
  "plan",
  "survey",
  "review",
  "pack",
  "load",
  "inStorageOrigin",
  "inTransit",
  "inStorage",
  "inStoragePartial",
  "deliver",
  "finalDelivery",
] as const;

export type PillName = (typeof statusPills)[number];

/**
 * Config to describe which pills should be filled
 * for a given order status
 */
export type StatusPillConfig = {
  /**
   * Which pill to apply the order status to
   */
  pill: PillName;
  /**
   * Whether we mark this pill as "complete" (green) when in the status
   * rather than in progress (yellow)
   */
  markComplete?: boolean;
  /**
   * Whether to show the completion star when in this status
   */
  showStar?: boolean;
};

/**
 * Maps depot order stauses to pills
 */
export const depotStatusToStatusPillMap: {
  [key in DepotStatus]: StatusPillConfig;
} = {
  RECEIVED: {
    pill: "plan",
  },
  APPROVED: {
    pill: "plan",
  },
  COUNSELING_COMPLETED: {
    pill: "survey",
  },
  SURVEY_SCHEDULED: {
    pill: "survey",
  },
  SURVEY_IN_PROGRESS: {
    pill: "survey",
  },
  SURVEY_COMPLETED: {
    pill: "review",
  },
  PLANNED: {
    pill: "review",
  },
  PACK_CREW_DISPATCHED: {
    pill: "pack",
  },
  PACKING_STARTED: {
    pill: "pack",
  },
  PACKING_COMPLETED: {
    pill: "pack",
    markComplete: true,
  },
  LOAD_CREW_DISPATCHED: {
    pill: "load",
  },
  LOADING_STARTED: {
    pill: "load",
  },
  IN_TRANSIT: {
    pill: "inTransit",
  },
  IN_STORAGE_IN_TRANSIT: {
    pill: "inStorage",
  },
  IN_STORAGE_PARTIAL: {
    pill: "inStorage",
  },
  IN_NON_TEMPORARY_STORAGE: {
    pill: "inStorage",
  },
  DELIVERY_CREW_DISPATCHED: {
    pill: "deliver",
  },
  DELIVERY_STARTED: {
    pill: "deliver",
  },
  DELIVERY_COMPLETED: {
    pill: "deliver",
    markComplete: true,
    showStar: true,
  },
  COMPLETED: {
    pill: "deliver",
    markComplete: true,
    showStar: true,
  },
  CANCELLED: {
    pill: "plan",
  },
  UNKNOWN: {
    pill: "plan",
  },
};

export const moveTrackerStepToStatusPillMap: {
  [key in MoveTrackerKey]?: StatusPillConfig;
} = {
  PACK: {
    pill: "pack",
  },
  LOAD: {
    pill: "load",
  },
  TRANSPORTATION: {
    pill: "inTransit",
  },
  DELIVERY: {
    pill: "deliver",
  },
  ARRIVED_AT_STORAGE_ORIGIN: {
    pill: "inStorageOrigin",
  },
  ARRIVED_AT_STORAGE_DESTINATION: {
    pill: "inStorage",
  },
  ARRIVED_AT_STORAGE_DESTINATION_PARTIAL: {
    pill: "inStoragePartial",
  },
  FINAL_DELIVERY: {
    pill: "finalDelivery",
  },
};
