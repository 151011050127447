"use client";
import { FormattedMessage } from "services/translation/FormattedMessage";
import { BackNav } from "components";
import { useOrder } from "services/orders/OrderGuard";
import { AddressChangeDialog } from "../../../common/AddressChangeDialog/AddressChangeDialog";
import { CounselingLayout } from "../components/CounselingLayout";
import { useCounselingSubmit } from "hooks/useCounselingSubmit";
import { counselingStatusToPathMap } from "types";
import { AddressCounselingCard } from "./AddressCounselingCard";
import { CounselingButton } from "../components/CounselingButton";
import { useState } from "react";

export function AddressOriginPage() {
  const order = useOrder();
  const [modalOpen, setModalOpen] = useState(false);
  const changeRequest = order.activeChangeRequest("ORIGIN_ADDRESS");
  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: "ADDRESSES_EXTRA_PICKUP",
  });

  const backPath = order.hasEntitlementsCounseling
    ? counselingStatusToPathMap.ENTITLEMENTS_FORMS
    : counselingStatusToPathMap.WELCOME;

  return (
    <CounselingLayout ariaLabel={"ariaLabels.addresses.origin"}>
      <BackNav href={backPath} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.addresses.origin.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id="counseling.addresses.origin.instructions" />
      </CounselingLayout.InfoText>
      <AddressCounselingCard
        mode="origin"
        changeRequest={changeRequest}
        toggleModal={() => setModalOpen(true)}
        address={order.origin}
      />
      <CounselingButton onClick={handleNext} isSubmitting={loading} />
      <AddressChangeDialog
        changeRequestType={"ORIGIN_ADDRESS"}
        locationId={order.originLocation?.id}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Origin"
      />
    </CounselingLayout>
  );
}
