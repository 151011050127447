import { useState, useCallback } from "react";

/**
 * Utility hook that forces an re-render of a component. This is used
 * to help us build a connecting hook for a spray paint model object. This
 * object is mutable and therefor will not trigger any re renders when values
 * change, so we need to rely forcing an update on change.
 * This generally should not be used for any other purpose.
 * @returns Function that will force a re-render
 */
export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return useCallback(() => setValue((value) => value + 1), []); // update state to force render
}
