import { useMemo } from "react";
import { useOrder, useRefreshOrder } from "services/orders";

/**
 * Provides the initial values and a submit function to send a new change
 * request for an storage in transit.
 */
export const useStorageChangeRequestForm = () => {
  const order = useOrder();
  const { refresh: refreshOrder } = useRefreshOrder();

  const initialValues = useMemo(
    () => ({
      storage: order.sitRequested ? "1" : "0",
    }),
    [order.sitRequested]
  );

  const submit = async (values: { storage: string }) => {
    const newValue = values.storage === "1";
    if (order.sitRequested !== newValue) {
      order.moveType.sitRequested = newValue ? true : false;
      await order.moveType.save();
      // We need to udpate the Order manually here since we updating the MoveType and then
      // the backend is updating Order, so we won't have the most recent Order data.
      refreshOrder();
      return true;
    }
    return false;
  };

  return { initialValues, submit };
};
