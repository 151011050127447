import { Typography } from "@mui/material";
import { FormattedMessage } from "services";
import { useOrder } from "services/orders";

export const SITFieldComponent: React.FC = () => {
  const order = useOrder();
  return (
    <div>
      <Typography variant="xxsHeading">
        <FormattedMessage id="moveTracker.address.changeRequest.StorageRequested" />
      </Typography>
      <div>
        <Typography
          variant="sBody"
          data-testid={`move-tracker-address-sit`}
          data-dd-privacy="mask"
        >
          {order.sitRequested ? "Yes" : "No"}
        </Typography>
      </div>
    </div>
  );
};
