import { useState } from "react";
import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { colors, theme } from "styles";
import { formatUSPhoneNumber } from "tools/formatPhoneNumber";

const Wrapper = styled.a`
  display: block;
  text-decoration: none;
  color: ${theme.palette.primary.main};
  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

type ContactDriverProps = {
  contactNumber: string;
  spName: string;
};

export function ContactCrew({ contactNumber, spName }: ContactDriverProps) {
  return (
    <Wrapper href={`tel:${contactNumber}`} data-testid="contact-crew-number">
      <Box
        bgcolor="#F2F8FC"
        border="1px solid rgba(16, 89, 144, 0.12)"
        borderRadius="4px"
        padding="16px"
      >
        <Stack direction="row" spacing="8px">
          <PhoneIcon />
          <div>
            <Box mb="4px">
              <Typography variant="mHeading">Contact Crew</Typography>
            </Box>
            <Box>{spName}</Box>
            <Typography variant="sBody" color={colors.linkBlue}>
              {formatUSPhoneNumber(contactNumber)}
            </Typography>
          </div>
        </Stack>
      </Box>
    </Wrapper>
  );
}
