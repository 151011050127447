import { Panel } from "components/common";
import { Service } from "models";
import { FormattedMessage, useIntl } from "services";
import { formatTime } from "@movehq/datetime";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useNotify } from "services/notification";
import {
  ServiceSubtype,
  useSwitchSurveyToVirtualMutation,
} from "__generated__/types";

export const OnsiteSurveyPanel: React.FC<{
  service: Service;
}> = ({ service }) => {
  const { formatMessage } = useIntl();
  const notify = useNotify();

  const [switchToVirtual, { loading }] = useSwitchSurveyToVirtualMutation({
    onCompleted: () => {
      service.subtype = ServiceSubtype.Virtual;
      service.updateUi();
      notify(
        "Your survey has been switched to virtual. To schedule, please contact customer care.",
        { variant: "success" }
      );
    },
    onError: (e) => {
      console.error(e);
      notify("Something went wrong. Please try again.", { variant: "error" });
    },
  });

  if (!service.plannedDatetimeRange || !service.plannedDatetimeRange.begin) {
    return (
      <Panel
        icon="calendar"
        title={<FormattedMessage id="surveyPanel.onsite.unscheduledTitle" />}
        variant="primary"
        ariaLabel={formatMessage({ id: "surveyPanel.onsite.unscheduledTitle" })}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Stack gap={1}>
              <FormattedMessage id="surveyPanel.onsite.unscheduledContent" />
              <Typography variant="sBody">
                Prefer to do the survey over video from your device?{" "}
                <a
                  onClick={() => {
                    if (service.id) {
                      switchToVirtual({
                        variables: {
                          input: { id: service.id },
                        },
                      });
                    } else {
                      console.error("Service is missing");
                      notify("Something went wrong. Please try again.", {
                        variant: "error",
                      });
                    }
                  }}
                >
                  Change to virtual
                </a>
                .
              </Typography>
            </Stack>
          </>
        )}
      </Panel>
    );
  }

  return (
    <Panel
      icon="calendar"
      title={<FormattedMessage id="surveyPanel.onsite.scheduledTitle" />}
      variant="primary"
      ariaLabel={formatMessage({ id: "surveyPanel.onsite.scheduledTitle" })}
    >
      <FormattedMessage
        id="surveyPanel.onsite.scheduledContent"
        values={{
          date: (
            <strong>
              {formatTime(
                service.plannedDatetimeRange.begin,
                "numericDateTimeZone"
              )}
            </strong>
          ),
        }}
      />
    </Panel>
  );
};
