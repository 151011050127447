import { AddressFormData } from "types";
import MapsSearch, { isUnexpected } from "@azure-rest/maps-search";
import { AzureKeyCredential } from "@azure/core-auth";
import { datadogRum } from "@datadog/browser-rum";

export const AZURE_SUBSCRIPTION_KEY =
  "ynBtjrGBsxHtFfGsdlbXRnon5dPvgFx5xYVLrtoNsGo";

const credential = new AzureKeyCredential(AZURE_SUBSCRIPTION_KEY);
const client = MapsSearch(credential);

/**
 * Geocode an address and returns the county name if found.
 * @param address The address to geocode
 * @returns The county name if found, otherwise null
 */
export const getCountyFromAddress = async (address: AddressFormData) => {
  try {
    const response = await client.path("/geocode").get({
      queryParameters: {
        addressLine: address.street1,
        postalCode: address.postalCode,
        adminDistrict: address.region,
        countryRegion: "US",
      },
    });

    if (isUnexpected(response)) {
      throw response.body.error;
    }

    if (!response.body.features || response.body.features.length === 0) {
      throw new Error("No results from Azure");
    }

    const county =
      response.body.features[0]!.properties?.address?.adminDistricts?.[1]
        .shortName;

    return county || null;
  } catch (error) {
    console.error("Error geocoding address in address change request");
    datadogRum.addError(error);
    return null;
  }
};
