import { ApplicationRecord, Customer } from "models";
import { useEffect, useMemo } from "react";

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

interface DecodedJWT {
  sub: string;
}

interface User {
  email: string;
}

/**
 * Hook to retrieve user info based on the current logged in user.
 * This is currently done by decoding the jwt, which is hacky but there is
 * no endpoint to retrieve the data. Eventually we will replace this with a request.
 */
export const useMe = (): User | null => {
  const jwt = ApplicationRecord.jwt;
  return useMemo(() => {
    if (!jwt) {
      return null;
    }
    try {
      const parsed = parseJwt(jwt) as DecodedJWT;
      return {
        email: parsed.sub,
      };
    } catch (err) {
      return null;
    }
  }, [jwt]);
};
