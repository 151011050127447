"use client";
import { PpmContent } from "../../common/PpmContent/PpmContent";
import { useOrder } from "../../../store";
import { useIntl } from "../../../services";
import styled from "@emotion/styled";
import { PageWrapper } from "components/common/PageWrapper";

const ContentContainer = styled.div({
  paddingTop: "1.5rem",
});
export function SelfMovePage() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const { customer, entitlement } = order;

  return (
    <>
      <PageWrapper
        aria-label={formatMessage({ id: "ariaLabels.entitlements" })}
      >
        <ContentContainer>
          <PpmContent customer={customer} entitlement={entitlement} />
        </ContentContainer>
      </PageWrapper>
    </>
  );
}
