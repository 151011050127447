"use client";
import { FormattedMessage } from "services";
import { BackNav, TemporalDatePicker, Card } from "components";
import { useRefreshOrder } from "services/orders";
import styled from "@emotion/styled";
import { counselingStatusToPathMap } from "types";
import { CounselingLayout } from "./components";
import { useOrder } from "store/";
import { useDateChangeRequestForm } from "hooks/useDateChangeRequestForm";
import { useCounselingSubmit } from "hooks/useCounselingSubmit";
import { CounselingButton } from "./components/CounselingButton";
import { Box, Typography } from "@mui/material";
import { nowPlainDate, Temporal } from "@movehq/datetime";
import { useMemo } from "react";
import { InfoPopover } from "components/common/ui/InfoPopover";
import { ServiceSubtype } from "__generated__/types";
import { useShouldSkipSurveyInOnboarding } from "hooks/useShouldSkipSurveyInOnboarding";

const DatePickerContainer = styled.div({
  marginBottom: "1rem",
});

// TODO use Temporal for date manipulation
export function MoveDatesPage() {
  const { refresh } = useRefreshOrder();
  const order = useOrder();

  const {
    handleSubmit,
    selectedDates,
    dates,
    setSelectedDate,
    areDatesSequential,
    minDate,
  } = useDateChangeRequestForm(order.moveStageDates, order.id!);

  const currentDate = useMemo(() => {
    return nowPlainDate();
  }, []);

  const isDateInTheFuture = (date: Temporal.PlainDate) => {
    return Temporal.PlainDate.compare(currentDate, date) < 0;
  };

  const areDatesSelectedInTheFuture = () => {
    return selectedDates.every(
      (date) => date !== null && isDateInTheFuture(date)
    );
  };

  const continueButtonActive =
    areDatesSelectedInTheFuture() && areDatesSequential();

  const skipSurveySchedule = useShouldSkipSurveyInOnboarding(order);

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: skipSurveySchedule ? "COMPLETE" : "SURVEY_SCHEDULE_TYPE",
    saveCallback: async () => {
      if (continueButtonActive) {
        try {
          await handleSubmit();
          await refresh();
          return true;
        } catch (err) {
          console.error(err);
        }
      }
      return false;
    },
  });

  const pickupWindowInfoCard = () => {
    return (
      <Card title="7-day Pickup/Load Scheduling Window">
        <ul>
          <li>
            This refers to the 7-day window during which your moving company is
            required to pickup/load your shipment
          </li>
          <li>
            Your pack date is the day your movers will be in your home to pack
            your belongings
          </li>
          <li>
            Your pickup/load date is the day your moving company physically
            removes your belongings from your home
          </li>
          <li>
            Your 7-day window is only used to schedule your pickup/load date
          </li>
          <li>
            Your requested pickup/load date should be your latest preferred
            pickup/load date; or the date you need to be out of your origin
            residence
          </li>
        </ul>
      </Card>
    );
  };

  return (
    <CounselingLayout
      ariaLabel={"ariaLabels.counseling.moveDates"}
      rightColumnContent={pickupWindowInfoCard()}
    >
      <BackNav href={counselingStatusToPathMap.ADDRESSES_EXTRA_DELIVERY} />
      <CounselingLayout.Heading>
        <FormattedMessage id="counseling.moveDates.title" />
      </CounselingLayout.Heading>
      <CounselingLayout.InfoText>
        <FormattedMessage id={"counseling.moveDates.instructions"} />
      </CounselingLayout.InfoText>
      {dates.map((moveStageDate, index) => {
        const label = `Requested ${moveStageDate.label} Date`;
        const isCompleted = moveStageDate.completedDate !== null;
        return (
          <DatePickerContainer key={moveStageDate.changeRequestKey}>
            <Box mb="16px" display="flex" alignItems="center">
              <Typography variant="mHeading" component="h3" mr="4px">
                {moveStageDate.label} Date
              </Typography>
              <InfoPopover contentMessageId={moveStageDate.description} />
            </Box>
            <TemporalDatePicker
              value={selectedDates[index]}
              onChange={(date) => setSelectedDate(index, date)}
              minDate={minDate(selectedDates[index - 1])}
              label="Choose date"
              ariaLabel={label}
              disabled={isCompleted}
            />
          </DatePickerContainer>
        );
      })}
      <CounselingButton
        onClick={handleNext}
        disabled={!continueButtonActive}
        isSubmitting={loading}
      />
    </CounselingLayout>
  );
}
