import classnames from "classnames";
import { Card, TwoColumn } from "../ui";
import { EntitlementsCard } from "../EntitlementsCard";
import { PersonalInfo } from "../../pages/HomePage/DetailsSection/components";
import ArrowSquareOut from "../../../../public/images/icons/ArrowSquareOut.svg";
import { FormattedMessage, useIntl } from "../../../services";
import styled from "@emotion/styled";
import { Customer, Entitlement } from "../../../models";
import { H1 } from "../Typography/typography";

const PageHeader = styled.div({
  flex: "0 0 100%",
});

const CardTitle = styled.div({
  display: "flex",
  alignContent: "center",
  justifySelf: "center",
  lineHeight: "2.25rem",
});

const UnstyledUnorderedList = styled.ul({
  listStyle: "none",
  paddingLeft: "0",
});

const SubTitle = styled.div({
  marginBottom: "1rem",
  marginTop: "0.5rem",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: "500",
  color: "#01314a",
});

const ListElement = styled.li({
  display: "flex",
  alignContent: "center",
  paddingBottom: "0.25rem",
});

interface PpmContentProps {
  customer: Customer;
  entitlement: Entitlement;
}

export function PpmContent({ customer, entitlement }: PpmContentProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      <PageHeader>
        <div className={classnames("page-title")}>
          <H1>
            <FormattedMessage id="counseling.ppm.title" />
          </H1>
          <SubTitle>
            <FormattedMessage id="counseling.ppm.subTitle" />
          </SubTitle>
        </div>
      </PageHeader>
      <TwoColumn>
        <EntitlementsCard customer={customer} entitlement={entitlement} />
        <div>
          <PersonalInfo customer={customer} entitlement={entitlement} />
          <Card
            title={
              <CardTitle>
                <FormattedMessage id="counseling.ppm.resource.link" />
              </CardTitle>
            }
            aria-label={formatMessage({ id: "ariaLabels.ppmLinks" })}
            variant="medium"
          >
            <UnstyledUnorderedList>
              <ListElement>
                <img src={ArrowSquareOut.src} style={{ marginRight: "1rem" }} />
                <a href={""}>
                  <FormattedMessage id="counseling.ppm.DpmoLink" />
                </a>
              </ListElement>
              <ListElement>
                <img src={ArrowSquareOut.src} style={{ marginRight: "1rem" }} />
                <a href={""}>
                  {" "}
                  <FormattedMessage id="counseling.ppm.TransportationOfficeLink" />
                </a>
              </ListElement>
              <ListElement>
                <img src={ArrowSquareOut.src} style={{ marginRight: "1rem" }} />
                <a href={""}>
                  {" "}
                  <FormattedMessage id="counseling.ppm.DiyMoveLink" />
                </a>
              </ListElement>
              <ListElement>
                <img src={ArrowSquareOut.src} style={{ marginRight: "1rem" }} />
                <a href={""}>
                  {" "}
                  <FormattedMessage id="counseling.ppm.PpmChecklistLink" />
                </a>
              </ListElement>
              <ListElement>
                <img src={ArrowSquareOut.src} style={{ marginRight: "1rem" }} />
                <a href={""}>
                  {" "}
                  <FormattedMessage id="counseling.ppm.MyMoveMil" />
                </a>
              </ListElement>
            </UnstyledUnorderedList>
          </Card>
        </div>
      </TwoColumn>
    </>
  );
}
