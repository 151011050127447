import { useState } from "react";
import { Button, TemporalDatePicker } from "components";
import styled from "@emotion/styled";
import { useNotify } from "services/notification";
import { useRefreshOrder } from "services/orders";
import { Modal } from "components";
import { useDateChangeRequestForm } from "hooks/useDateChangeRequestForm";
import { MoveStageDate } from "types";
import { Typography } from "@mui/material";

const DatePickerContainer = styled.div({
  marginBottom: "1rem",
});

const ButtonContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "0.5rem",
});

interface DateChangeDialogProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  orderId: string;
  moveStageDates: MoveStageDate[];
}

export function DateChangeDialog({
  openModal,
  setOpenModal,
  moveStageDates,
  orderId,
}: DateChangeDialogProps) {
  const [submitting, setSubmitting] = useState(false);
  const { refresh } = useRefreshOrder();
  const notify = useNotify();

  const {
    handleSubmit,
    selectedDates,
    dates,
    setSelectedDate,
    clearSelectedDates,
    areDatesSequential,
    minDate,
  } = useDateChangeRequestForm(moveStageDates, orderId);

  const disableSubmit = submitting || !areDatesSequential();

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const result = await handleSubmit();
      if (!result) {
        setSubmitting(false);
        notify("No changes were made.", {
          variant: "warning",
        });
        return;
      }
      await refresh();
      setSubmitting(false);
      setOpenModal(false);
      notify("Your move dates have been submitted and are awaiting approval.", {
        variant: "success",
      });
    } catch (err) {
      setSubmitting(false);
      notify("Something went wrong with your request to update move dates.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Request Date Change"
      >
        <div>
          {dates.map((moveStageDate, index) => {
            const label = `Requested ${moveStageDate.label} Date`;
            const isCompleted = Boolean(moveStageDate.completedDate);
            return (
              <DatePickerContainer key={moveStageDate.changeRequestKey}>
                <TemporalDatePicker
                  value={selectedDates[index]}
                  name={moveStageDate.label}
                  onChange={(date) => setSelectedDate(index, date)}
                  minDate={minDate(selectedDates[index - 1])}
                  disabled={isCompleted}
                  label={label}
                  ariaLabel={label}
                />
                {isCompleted && (
                  <Typography variant="xsBody">
                    {moveStageDate.label} is already completed.
                  </Typography>
                )}
              </DatePickerContainer>
            );
          })}
          <ButtonContainer>
            <Button
              data-testid="cancel-request"
              variant="secondary"
              size="block"
              onClick={() => {
                clearSelectedDates();
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="submit-request"
              size="block"
              onClick={() => {
                onSubmit();
              }}
              isSubmitting={submitting}
              disabled={disableSubmit}
            >
              Submit Request
            </Button>
          </ButtonContainer>
        </div>
      </Modal>
    </>
  );
}
