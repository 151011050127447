import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import rulesJson from "assets/entitlements/consignment-info.json";
import { Order } from "models";
import { useOrder } from "store";

/**
 * Gets list of rules to render
 * @param order
 */
const getRules = (order: Order) => {
  const destination = order.destination;
  return rulesJson.filter((rule) => rule.state === destination.region);
};

export const EntitlementsAdditionalInfo: React.FC = () => {
  const order = useOrder();
  const rules = getRules(order);

  if (rules.length === 0) {
    return null;
  }

  return (
    <Box mb="16px">
      <Box mb="16px">
        <Typography variant="xlHeading">
          Information regarding your delivery location
        </Typography>
      </Box>
      {rules.map((rule, index) => {
        return (
          <Box pb="16px" key={index}>
            <Typography variant="mBody" component="p">
              {rule.content}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
