import { useOrder } from "services/orders/OrderGuard";
import { Button, HighlightUnbackedData, Panel } from "components";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { FormattedMessage, useIntl } from "services";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

function renderMessage(isDelivered: boolean) {
  if (isDelivered) {
    return <FormattedMessage id="reviewYourMove.deliveredMessage" />;
  }
  return (
    <FormattedMessage
      id="reviewYourMove.message"
      values={{
        action: <FormattedMessage id="reviewYourMove.landingPageAction" />,
      }}
    />
  );
}

function reviewItemOnClick(
  router: AppRouterInstance,
  setIsLoading: (isLoading: boolean) => void
) {
  setIsLoading(true);
  return router.push("/inventory");
}

export function ReviewYourMovePanel() {
  const { shouldAllowCargoClaims } = useOrder();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { formatMessage } = useIntl();
  return (
    <Panel
      icon="boxes_and_lamp"
      title={<FormattedMessage id="reviewYourMove.title" />}
      ariaLabel={formatMessage({ id: "ariaLabels.reviewYourMoveLabel" })}
    >
      <div aria-label={formatMessage({ id: "ariaLabels.reviewYourMoveLabel" })}>
        <p>
          <HighlightUnbackedData>
            {renderMessage(shouldAllowCargoClaims)}
          </HighlightUnbackedData>
        </p>
        <Button
          size="medium"
          style={{ width: "auto" }}
          onClick={() => reviewItemOnClick(router, setIsLoading)}
          disabled={isLoading}
        >
          File a Claim
        </Button>
      </div>
    </Panel>
  );
}
